import gql from 'graphql-tag'
import { userFragment } from './user.fragment'

export const cardActivityFragment = gql`
  fragment CardActivityFragment on card_activity {
    id
    created_at
    action
    actor {
      ...UserFragment
    }
  }
  ${userFragment}
`
