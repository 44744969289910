import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Week } from '../hooks/use-week'

type Props = {
  week: Week
  gotoPrevWeek: () => void
  gotoNextWeek: () => void
}

export function TimeclockDatePicker({
  week,
  gotoPrevWeek,
  gotoNextWeek,
}: Props) {
  return (
    <div className="text-gray-800 text-lg flex justify-between max-w-7xl mx-auto py-4">
      <span className="inline-flex rounded-md shadow-sm">
        <button
          type="button"
          onClick={gotoPrevWeek}
          className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 active:text-gray-800 active:bg-gray-50"
        >
          <FontAwesomeIcon icon={['fas', 'angle-left']} />
        </button>
      </span>
      {new Date(week.startOfWeek).toLocaleDateString('en-US')}
      {' - '}
      {new Date(week.endOfWeek).toLocaleDateString('en-US')}
      <span className="inline-flex rounded-md shadow-sm">
        <button
          type="button"
          onClick={gotoNextWeek}
          className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 active:text-gray-800 active:bg-gray-50"
        >
          <FontAwesomeIcon icon={['fas', 'angle-right']} />
        </button>
      </span>
    </div>
  )
}
