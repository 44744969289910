import React from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import * as Sentry from '@sentry/browser'
import { useMutation } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  UpdateCardModalMutation,
  UpdateCardModalMutationVariables,
  CardModalFragment,
  CardModalQuery,
  CardModalQueryVariables,
} from '../graphql/optitorque-kanban-graphql'
import { cardModalQuery } from '../graphql/queries/cardModal.query'
import { cardModalFragment } from '../graphql/fragments/cardModal.fragment'
import { updateCardModalMutation } from '../graphql/mutations/updateCardModal.mutation'
import { Editable } from './Editable'
import { CardStateIndicator } from './CardStateIndicator'
import { KanbanCardModalDivider } from './KanbanCardModalDivider'

type Props = {
  card: CardModalFragment
}

const cardSchema = yup.object({
  title: yup.string().required('Required'),
  description: yup.string().required('Required'),
})

export function KanbanCardModalBody(props: Props) {
  const [update] = useMutation<
    UpdateCardModalMutation,
    UpdateCardModalMutationVariables
  >(updateCardModalMutation)

  const handleSubmit = (values: { title: string; description: string }) => {
    update({
      variables: {
        ids: {
          id: props.card.id,
        },
        input: {
          title: values.title,
          description: values.description,
        },
      },
      optimisticResponse: {
        update_cards_by_pk: {
          ...props.card,
          title: values.title,
          description: values.description,
        },
      },
      update: (cache, { data }) => {
        if (!data?.update_cards_by_pk) return

        let fragmentID = `${data.update_cards_by_pk.__typename}:${data.update_cards_by_pk.id}`

        let card = cache.readFragment<CardModalFragment>({
          id: fragmentID,
          fragment: cardModalFragment,
          fragmentName: 'CardModalFragment',
        })

        if (!card) {
          // TODO tell user about error
          Sentry.withScope((scope) => {
            scope.setLevel(Sentry.Severity.Error)
            scope.setExtras({
              data,
              fragmentID,
            })
            Sentry.captureMessage('no card fragment data')
          })
          return
        }

        let newCard = {
          ...card,
          title: data.update_cards_by_pk.title,
          description: data.update_cards_by_pk.description,
        }

        try {
          cache.writeFragment<CardModalFragment>({
            id: fragmentID,
            fragment: cardModalFragment,
            fragmentName: 'CardModalFragment',
            data: newCard,
          })
        } catch (e) {
          Sentry.captureException(e)
        }

        let cardModalData = cache.readQuery<
          CardModalQuery,
          CardModalQueryVariables
        >({
          query: cardModalQuery,
          variables: {
            id: card.id,
          },
        })

        if (!cardModalData) {
          // TODO tell user about error
          Sentry.withScope((scope) => {
            scope.setLevel(Sentry.Severity.Error)
            scope.setExtras({
              cardModalData,
              data,
              fragmentID,
            })
            Sentry.captureMessage('no card query data')
          })
          return
        }

        try {
          cache.writeQuery<CardModalQuery, CardModalQueryVariables>({
            query: cardModalQuery,
            variables: {
              id: card.id,
            },
            data: {
              cards_by_pk: newCard,
            },
          })
        } catch (e) {
          Sentry.captureException(e)
        }
      },
    })
  }

  const formik = useFormik({
    initialValues: {
      title: props.card.title,
      description: props.card.description,
    },
    validationSchema: cardSchema,
    onSubmit: handleSubmit,
  })

  const moveCursorToEnd = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    let end = e.currentTarget.value.length
    e.currentTarget.setSelectionRange(end, end)
  }

  return (
    <>
      <Editable
        onSave={formik.submitForm}
        renderText={(textProps) => (
          <div className="flex items-center">
            <h1
              className="flex-auto text-lg leading-8 font-semibold hover:show-child"
              {...textProps}
            >
              {props.card.title}
              <FontAwesomeIcon
                icon={['fas', 'pencil']}
                size="sm"
                className="ml-2 hidden cursor-pointer"
                color="#718096"
              />
            </h1>
            <CardStateIndicator
              state={props.card.state}
              size={4}
              className="flex-shrink"
            />
          </div>
        )}
        renderInput={(inputProps) => (
          <div className="flex items-center justify-between">
            <input
              id="title"
              type="text"
              className="block flex-auto mr-4 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
              placeholder="Card Title"
              {...inputProps}
              onChange={formik.handleChange}
              value={formik.values.title}
              autoFocus
            />
            <CardStateIndicator
              state={props.card.state}
              size={4}
              className="flex-shrink"
            />
          </div>
        )}
      />
      <div className="mt-6">
        <KanbanCardModalDivider label="Description" />

        <Editable
          onSave={formik.submitForm}
          manualSave
          renderText={(textProps) => (
            <p
              className="leading-tight text-black mt-3 hover:show-child whitespace-pre-wrap"
              {...textProps}
            >
              {props.card.description}
              <FontAwesomeIcon
                icon={['fas', 'pencil']}
                size="xs"
                className="ml-2 hidden cursor-pointer"
                color="#718096"
              />
            </p>
          )}
          renderInput={(inputProps, formProps) => (
            <div className="mt-1">
              <textarea
                id="description"
                name="description"
                rows={4}
                cols={80}
                autoFocus
                {...inputProps}
                onChange={formik.handleChange}
                value={formik.values.description}
                onFocus={moveCursorToEnd}
                className="block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
              ></textarea>
              <div className="flex items-center justify-end mt-3 space-x-2">
                <span className="rounded-md shadow-sm">
                  <button
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    type="button"
                    onClick={formProps.submit}
                  >
                    Save
                  </button>
                </span>
                <span className="rounded-md shadow-sm">
                  <button
                    className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 active:text-gray-800 active:bg-gray-50"
                    type="button"
                    onClick={formProps.cancel}
                  >
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          )}
        />
      </div>
    </>
  )
}
