import React from 'react'
import * as Sentry from '@sentry/browser'
import { useApolloClient, useQuery } from '@apollo/client'
import {
  DragDropContext,
  DraggableLocation,
  DropResult,
} from 'react-beautiful-dnd'
import { calculateOrder } from '../calculate-order'
import { cardFragment } from '../graphql/fragments/card.fragment'
import { updateCardMutation } from '../graphql/mutations/updateCard.mutation'
import {
  CardFragment,
  ColumnsQuery,
  UpdateCardMutation,
  UpdateCardMutationVariables,
} from '../graphql/optitorque-kanban-graphql'
import { columnsQuery } from '../graphql/queries/columns.query'
import { columnsSubscription } from '../graphql/subscriptions/columns.subscription'
import { KanbanColumn } from './KanbanColumn'

function dragLocationChanged(
  source: DraggableLocation,
  destination: DraggableLocation
) {
  return (
    source.droppableId !== destination.droppableId ||
    source.index !== destination.index
  )
}

type Props = {
  openKanbanCardModal: (cardID: number) => void
}

export function Board(props: Props) {
  const { loading, error, data, subscribeToMore } = useQuery<ColumnsQuery>(
    columnsQuery
  )

  const client = useApolloClient()

  React.useEffect(() => {
    let unsub = subscribeToMore({
      document: columnsSubscription,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        return subscriptionData.data
      },
    })
    return () => unsub()
  }, [subscribeToMore])

  if (loading) return null
  if (error) return null

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    if (!dragLocationChanged(source, destination)) {
      return
    }

    if (!data) {
      return
    }

    const sourceCol = data.columns.find(
      (col) => `${col.id}` === source.droppableId
    )
    const destCol = data.columns.find(
      (col) => `${col.id}` === destination.droppableId
    )

    if (!sourceCol || !destCol) return

    if (sourceCol.id !== destCol.id) {
      let order = 0.0
      let sourceCard = sourceCol.cards[source.index]
      let destCard = destCol.cards[destination.index]

      let prevCard =
        destination.index - 1 < 0
          ? undefined
          : destCol.cards[destination.index - 1]
      order = calculateOrder(
        prevCard ? prevCard.order : destCard ? destCard.order / 2 : 1.0,
        destCard ? destCard.order : 99999999.99999999
      )

      client.mutate<UpdateCardMutation, UpdateCardMutationVariables>({
        mutation: updateCardMutation,
        variables: {
          ids: { id: sourceCard.id },
          input: {
            column_id: destCol.id,
            order: order,
          },
        },
        optimisticResponse: {
          update_cards_by_pk: {
            ...sourceCard,
            order,
            column_id: destCol.id,
          },
        },
        update: (cache, res) => {
          if (!res.data?.update_cards_by_pk) return

          let cardCacheId = `${res.data.update_cards_by_pk.__typename}:${res.data.update_cards_by_pk.id}`
          let card = cache.readFragment<CardFragment>({
            fragment: cardFragment,
            fragmentName: 'CardFragment',
            id: cardCacheId,
          })

          if (card) {
            let updatedCard = {
              ...card,
              order: res.data.update_cards_by_pk.order,
              column_id: res.data.update_cards_by_pk.column_id,
            }
            try {
              cache.writeFragment<CardFragment>({
                fragment: cardFragment,
                fragmentName: 'CardFragment',
                id: cardCacheId,
                data: updatedCard,
              })
            } catch (e) {
              Sentry.captureException(e)
            }
          }

          let data = cache.readQuery<ColumnsQuery>({
            query: columnsQuery,
          })

          if (data) {
            let sourceColData = data.columns.find(
              (col) => col.id === sourceCol.id
            )
            if (sourceColData) {
              let sourceColCards = [...sourceColData.cards]
              let splicedCard = sourceColCards.splice(source.index, 1)

              if (splicedCard.length === 1) {
                let destColData = data.columns.find(
                  (col) => col.id === destCol.id
                )
                if (destColData) {
                  let destColCards = [...destColData.cards]
                  destColCards.splice(destination.index, 0, splicedCard[0])

                  let updatedCols = data.columns.map((col) => {
                    if (col.id === sourceCol.id) {
                      return {
                        ...col,
                        cards: sourceColCards,
                      }
                    }
                    if (col.id === destCol.id) {
                      return {
                        ...col,
                        cards: destColCards,
                      }
                    }
                    return col
                  })

                  try {
                    cache.writeQuery<ColumnsQuery>({
                      query: columnsQuery,
                      data: {
                        ...data,
                        columns: updatedCols,
                      },
                    })
                  } catch (e) {
                    Sentry.captureException(e)
                  }
                }
              }
            }
          }
        },
      })
    }

    if (sourceCol.id === destCol.id) {
      let order = 0.0
      let sourceCard = sourceCol.cards[source.index]
      let destCard = sourceCol.cards[destination.index]

      if (!destCard) {
        order = 1.0
      } else if (sourceCard.order < destCard.order) {
        let nextCard =
          destination.index + 1 >= sourceCol.cards.length
            ? undefined
            : sourceCol.cards[destination.index + 1]
        order = calculateOrder(
          destCard.order,
          nextCard ? nextCard.order : 99999999.99999999
        )
      } else {
        let prevCard =
          destination.index - 1 < 0
            ? undefined
            : sourceCol.cards[destination.index - 1]

        // No prev card, we use a default distance between destCard.order / 2 and destCard.order
        let after = 0
        if (!prevCard) {
          after = calculateOrder(destCard.order / 2, destCard.order)
        }

        order = calculateOrder(
          prevCard ? prevCard.order : after,
          destCard.order
        )
      }
      client.mutate<UpdateCardMutation, UpdateCardMutationVariables>({
        mutation: updateCardMutation,
        variables: {
          ids: { id: sourceCard.id },
          input: {
            order: order,
          },
        },
        optimisticResponse: {
          update_cards_by_pk: {
            ...sourceCard,
            order,
          },
        },
        update: (cache, res) => {
          if (!res.data?.update_cards_by_pk) return
          let cacheId = `${res.data.update_cards_by_pk.__typename}:${res.data.update_cards_by_pk.id}`
          let card = cache.readFragment<CardFragment>({
            fragment: cardFragment,
            fragmentName: 'CardFragment',
            id: cacheId,
          })
          if (card) {
            let updatedCard = {
              ...card,
              order: res.data.update_cards_by_pk.order,
            }
            try {
              cache.writeFragment<CardFragment>({
                fragment: cardFragment,
                fragmentName: 'CardFragment',
                id: cacheId,
                data: updatedCard,
              })
            } catch (e) {
              Sentry.captureException(e)
            }
          }
        },
      })
    }
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <main className="bg-white flex p-8 flex-1 overflow-auto">
        {data &&
          data.columns.map((col) => {
            return (
              <KanbanColumn
                key={col.id}
                colId={col.id}
                title={col.title}
                cards={col.cards}
                openKanbanCardModal={props.openKanbanCardModal}
              />
            )
          })}
      </main>
    </DragDropContext>
  )
}
