import gql from 'graphql-tag'

export const uploadRequestMutation = gql`
  mutation UploadRequestMutation {
    uploadRequest {
      id
      presigned_url
    }
  }
`
