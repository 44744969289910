import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useURLSearchParams } from '../hooks/use-url-search-params'
import { useQuery } from '@apollo/client'
import {
  UserClockpunchesQuery,
  UserClockpunchesQueryVariables,
} from '../graphql/optitorque-kanban-graphql'
import { userClockpunchesQuery } from '../graphql/queries/userClockpunches.query'
import { useWeek } from '../hooks/use-week'
import { AppPage } from './AppPage'
import { TimeclockUserEditRow } from './TimeclockUserEditRow'
import { TimeclockDatePicker } from './TimeclockDatePicker'
import { useSyncWeekToQueryString } from '../hooks/use-sync-week-to-querystring'
import { DeletePunchModal } from './DeletePunchModal'
import { Avatar } from './Avatar'
import { useBoolean } from '../hooks/use-boolean'
import { TimeclockUserInsertRow } from './TimeclockUserInsertRow'

type Props = {}

export function TimeclockUserEditPage(props: Props) {
  let history = useHistory()
  let { userId } = useParams<{ userId: string }>()
  let query = useURLSearchParams()

  let initialWeek: Date
  let qsWeek = query.get('week')

  if (!qsWeek) {
    initialWeek = new Date()
  } else {
    let [year, month, day] = qsWeek.split('-')
    initialWeek = new Date(
      Date.UTC(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10))
    )
    initialWeek = new Date(
      initialWeek.getTime() + initialWeek.getTimezoneOffset() * 60 * 1000
    )
  }

  let { week, gotoNextWeek, gotoPrevWeek } = useWeek(initialWeek)

  useSyncWeekToQueryString(week.startOfWeek)

  const { data, loading, error } = useQuery<
    UserClockpunchesQuery,
    UserClockpunchesQueryVariables
  >(userClockpunchesQuery, {
    variables: {
      userId: parseInt(userId, 10),
      startOfWeek: week.startOfWeek,
      endOfWeek: week.endOfWeek,
    },
    fetchPolicy: 'cache-and-network',
  })

  let [deletingPunchId, setDeletingPunchId] = useState<number | null>(null)
  let isDeletePunchModalOpen = deletingPunchId !== null

  let [isInsertingPunch, setInsertingPunch] = useBoolean(false)

  return (
    <AppPage>
      {error ? null : (
        <div className="h-full w-full bg-cool-gray-100">
          <div className="sm:px-6 lg:px-8 py-4">
            {data?.users_by_pk ? (
              <div className="flex flex-col text-center bg-white max-w-7xl mx-auto">
                <div className="flex-1 flex flex-col pt-4">
                  <Avatar
                    tailwindSize={32}
                    email={data.users_by_pk.email}
                    className="flex-shrink-0 mx-auto bg-black"
                  />
                  <h3 className="mt-6 text-gray-900 text-sm leading-5 font-medium">
                    {data.users_by_pk.name}
                  </h3>
                </div>
              </div>
            ) : null}
            <TimeclockDatePicker
              week={week}
              gotoPrevWeek={gotoPrevWeek}
              gotoNextWeek={gotoNextWeek}
            />
            <div className="flex flex-col max-w-7xl mx-auto">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Date
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Clock In
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Clock Out
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Code
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {!loading &&
                        !data?.users_by_pk?.clockpunches.length &&
                        !isInsertingPunch ? (
                          <tr>
                            <td
                              colSpan={3}
                              className="px-6 py-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900"
                            >
                              No recent punches
                            </td>
                          </tr>
                        ) : null}
                        {data?.users_by_pk?.clockpunches.map((punch, idx) => (
                          <TimeclockUserEditRow
                            key={punch.id}
                            idx={idx}
                            punch={punch}
                            onDelete={(punchId) => setDeletingPunchId(punchId)}
                          />
                        ))}
                        {isInsertingPunch && data?.users_by_pk ? (
                          <TimeclockUserInsertRow
                            week={week}
                            user={data.users_by_pk}
                            onDone={() => setInsertingPunch.off()}
                          />
                        ) : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between max-w-7xl mx-auto mt-8">
              <button
                type="button"
                onClick={() => history.push(`/timeclock`)}
                className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Back
              </button>
              <button
                type="button"
                onClick={() => setInsertingPunch.on()}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Add time punch
              </button>
            </div>
          </div>
        </div>
      )}
      <DeletePunchModal
        isOpen={isDeletePunchModalOpen}
        punchId={deletingPunchId!}
        onRequestClose={() => setDeletingPunchId(null)}
      />
    </AppPage>
  )
}
