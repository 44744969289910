import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client'
import { WebSocketLink } from '@apollo/link-ws'
import { setContext } from '@apollo/link-context'
import { getMainDefinition } from '@apollo/client/utilities'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { GRAPHQL_URL, REALTIME_GRAPHQL_URL } from './constants'

const getHeaders = (token: string) => {
  const headers: { [key: string]: any } = {}
  if (token) {
    headers.authorization = `Bearer ${token}`
  }
  return headers
}

export const makeApolloClient = (token: string) => {
  const authHeaders = getHeaders(token)
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        ...authHeaders,
      },
    }
  })

  const httpLink = new HttpLink({
    uri: GRAPHQL_URL,
    fetch,
  })

  const wsLink: WebSocketLink = new WebSocketLink(
    new SubscriptionClient(REALTIME_GRAPHQL_URL, {
      reconnect: true,
      timeout: 30000,
      connectionParams: () => ({ headers: authHeaders }),
      connectionCallback: (err) =>
        // @ts-ignore
        err && wsLink.subscriptionClient.close(false, false),
    })
  )

  const apiLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    // @ts-ignore
    wsLink,
    httpLink
  )

  const client = new ApolloClient({
    // @ts-ignore
    link: ApolloLink.from([authLink, apiLink]),
    cache: new InMemoryCache({
      addTypename: true,
    }),
  })

  return client
}
