import gql from 'graphql-tag'
import { cardModalFragment } from '../fragments/cardModal.fragment'

export const cardModalSubscription = gql`
  subscription CardModalSubscription($id: Int!) {
    cards_by_pk(id: $id) {
      ...CardModalFragment
    }
  }
  ${cardModalFragment}
`
