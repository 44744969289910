import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  ApolloClient,
  ApolloProvider as _ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client'
import jwtDecode from 'jwt-decode'
import { useAuth0 } from '@auth0/auth0-react'
import { makeApolloClient } from '../apollo'
import { LoginPage } from './LoginPage'

export type HasuraJWT = {
  'https://hasura.io/jwt/claims'?: {
    'x-hasura-default-role': string
    'x-hasura-allowed-roles': string[]
    'x-hasura-user-id': string
  }
  iss?: string
  sub?: string
  aud?: string[]
  iat?: number
  exp?: number
  azp?: string
  scope?: string
}

export const AccessTokenContext = createContext('')
export const useAccessToken = () => {
  let token = useContext(AccessTokenContext)
  return jwtDecode<HasuraJWT | null>(token)
}

export const ApolloProvider: FC = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  const [accessToken, setAccessToken] = useState('')
  const [apolloClient, setApolloClient] = useState<
    ApolloClient<NormalizedCacheObject>
  >()

  useEffect(() => {
    if (isAuthenticated && getAccessTokenSilently && !apolloClient) {
      getAccessTokenSilently().then((token) => {
        setAccessToken(token)

        const client = makeApolloClient(token)
        setApolloClient(client)
      })
    }
  }, [getAccessTokenSilently, apolloClient, isAuthenticated])

  if (!apolloClient) return <LoginPage />

  return (
    <AccessTokenContext.Provider value={accessToken}>
      {/* eslint-disable-next-line */}
      <_ApolloProvider client={apolloClient}>{children}</_ApolloProvider>
    </AccessTokenContext.Provider>
  )
}
