import gql from 'graphql-tag'
import { clockpunchFragment } from '../fragments/clockpunch.fragment'

export const updateClockpunchMutation = gql`
  mutation UpdateClockPunchMutation(
    $id: Int!
    $punchIn: timestamptz!
    $punchOut: timestamptz!
  ) {
    update_clockpunches_by_pk(
      pk_columns: { id: $id }
      _set: { punch_in: $punchIn, punch_out: $punchOut }
    ) {
      ...ClockpunchFragment
    }
  }
  ${clockpunchFragment}
`
