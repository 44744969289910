import React from 'react'
import { useBoolean } from '../hooks/use-boolean'

type InputProps = {
  onBlur: undefined | (() => void)
  onKeyDown: undefined | ((event: React.KeyboardEvent<Element>) => void)
}

type FormProps = {
  cancel: () => void
  submit: () => void
}

type TextProps = {
  onClick: () => void
}

type Props = {
  onSave: () => Promise<void | undefined> | void
  renderInput: (inputProps: InputProps, formProps: FormProps) => JSX.Element
  renderText: (textProps: TextProps) => JSX.Element
  manualSave?: boolean
}

const enterKey = 'Enter'
const keys = ['Escape', 'Tab']
const allKeys = [...keys, enterKey]

export function Editable(props: Props) {
  const [isEditing, { on, off }] = useBoolean(false)

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const { key } = event
    const { nodeName } = event.currentTarget
    if (nodeName === 'TEXTAREA') {
      // textarea should not close when doing shift+enter
      if ((key === enterKey && !event.shiftKey) || keys.includes(key)) {
        props.onSave()
        off()
      }
    } else if (allKeys.includes(key)) {
      props.onSave()
      off()
    }
  }

  if (isEditing) {
    const inputProps = {
      onBlur: !props.manualSave ? off : undefined,
      onKeyDown: !props.manualSave ? handleKeyDown : undefined,
    }
    const formProps = {
      cancel: off,
      submit: () => {
        props.onSave()
        off()
      },
    }
    return props.renderInput(inputProps, formProps)
  }

  const textProps = {
    onClick: on,
  }

  return props.renderText(textProps)
}
