import React from 'react'

type Props = {
  label: string
}

export function KanbanCardModalDivider({ label }: Props) {
  return (
    <h5 className="text-xs text-gray-400 flex items-center mb-1 w-full">
      {label}
      <span className="ml-1 block flex-auto border-t border-solid border-gray-200"></span>
    </h5>
  )
}
