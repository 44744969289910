import React from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'

const cardSchema = yup.object({
  title: yup.string().required('Required'),
  description: yup.string().required('Required'),
})

const errorClasses = 'mt-2 -mb-2 text-red-500 text-sm'

type Props = {
  onCancel: () => void
  onSave: (...args: any[]) => void
}

export const KanbanCardForm = ({ onCancel, onSave }: Props) => {
  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
    },
    validationSchema: cardSchema,
    onSubmit: onSave,
  })

  return (
    <form
      className="mt-2 bg-white p-3 border border-gray-400 rounded flex flex-col mb-1"
      onSubmit={formik.handleSubmit}
    >
      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="sm:col-span-6">
          <label
            htmlFor="title"
            className="block text-sm font-medium leading-5 text-gray-700"
          >
            Title
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              id="title"
              name="title"
              type="text"
              autoFocus
              onChange={formik.handleChange}
              value={formik.values.title}
              className="flex-1 form-input block w-full rounded-md sm:text-sm sm:leading-5"
            />
          </div>
          {formik.touched.title && formik.errors.title ? (
            <p className={errorClasses}>{formik.errors.title}</p>
          ) : null}
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="description"
            className="block text-sm font-medium leading-5 text-gray-700"
          >
            Description
          </label>
          <div className="mt-1 rounded-md shadow-sm">
            <textarea
              id="description"
              name="description"
              rows={4}
              onChange={formik.handleChange}
              value={formik.values.description}
              className="block w-full sm:text-sm sm:leading-5 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
            ></textarea>
          </div>
          {formik.touched.description && formik.errors.description ? (
            <p className={errorClasses}>{formik.errors.description}</p>
          ) : null}
        </div>
      </div>

      <div className="flex items-center justify-end mt-3 space-x-2">
        <span className="rounded-md shadow-sm">
          <button
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 active:bg-indigo-700"
            type="submit"
          >
            Save
          </button>
        </span>
        <span className="rounded-md shadow-sm">
          <button
            className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 active:text-gray-800 active:bg-gray-50"
            type="button"
            onClick={onCancel}
          >
            Cancel
          </button>
        </span>
      </div>
    </form>
  )
}
