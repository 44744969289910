import gql from 'graphql-tag'
import { cardModalFragment } from '../fragments/cardModal.fragment'

export const updateCardModalMutation = gql`
  mutation UpdateCardModalMutation(
    $input: cards_set_input!
    $ids: cards_pk_columns_input!
  ) {
    update_cards_by_pk(_set: $input, pk_columns: $ids) {
      ...CardModalFragment
    }
  }
  ${cardModalFragment}
`
