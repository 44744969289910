import React from 'react'
import { Board } from './Board'
import { AppPage } from './AppPage'
import { KanbanCardModal } from './KanbanCardModal'

export function BoardPage() {
  // TODO: refactor to a reducer so we only need calculate urlsearchparams once
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(() => {
    let params = new URLSearchParams(window.location.search)
    return !!params.get('cardID')
  })
  const [cardID, setCardID] = React.useState<number | undefined>(() => {
    let params = new URLSearchParams(window.location.search)
    let c = params.get('cardID')
    if (c) {
      return parseInt(c, 10)
    }
    return undefined
  })

  React.useEffect(() => {
    let params = new URLSearchParams(window.location.search)
    if (cardID) {
      params.set('cardID', `${cardID}`)
    } else {
      params.delete('cardID')
    }

    if (Array.from(params).length > 0) {
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${params.toString()}`
      )
    } else {
      window.history.replaceState({}, '', window.location.pathname)
    }
  }, [cardID])

  const openModal = React.useCallback((id: number) => {
    setCardID(id)
    setIsModalOpen(true)
  }, [])

  const closeModal = () => {
    setCardID(undefined)
    setIsModalOpen(false)
  }

  return (
    <AppPage>
      <Board openKanbanCardModal={openModal} />
      {cardID && (
        <KanbanCardModal
          id={cardID}
          isOpen={isModalOpen}
          onClose={closeModal}
        />
      )}
    </AppPage>
  )
}
