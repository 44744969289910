import React from 'react'
import Modal from 'react-modal'
import * as Sentry from '@sentry/browser'
import { useQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CardModalQuery,
  CardModalQueryVariables,
  CardModalSubscription,
  CardModalSubscriptionVariables,
} from '../graphql/optitorque-kanban-graphql'
import { cardModalQuery } from '../graphql/queries/cardModal.query'
import { cardModalSubscription } from '../graphql/subscriptions/card.subscription'
import { UploadProvider } from '../hooks/use-upload'
import { useIsAllowed } from '../hooks/use-is-allowed'
import { userId } from '../user-id'
import { KanbanCardModalBody } from './KanbanCardModalBody'
import { KanbanCardModalDivider } from './KanbanCardModalDivider'
import { ChangeCardStateDropdown } from './ChangeCardStateDropdown'
import { KanbanCardModalTimeTracking } from './KanbanCardModalTimeTracking'
import { AttachFilesAction } from './AttachFilesAction'
import { DeleteCardAction } from './DeleteCardAction'
import { CopyTaskLinkAction } from './CopyTaskLinkAction'
import { KanbanCardModalAttachments } from './KanbanCardModalAttachments'
import { KanbanCardModalComments } from './KanbanCardModalComments'
import { KanbanCardModalActivity } from './KanbanCardModalActivity'
import { ChangeCardPriorityDropdown } from './ChangeCardPriorityDropdown'

const customStyles = {
  content: {
    top: '50px',
    left: '50%',
    transform: 'translateX(-50%)',
    maxWidth: '94vw',
    maxHeight: '90vh',
    width: '980px',
    height: '100%',
    boxShadow: '0 8px 16px rgba(0,0,0,.25)',
  },
  overlay: {
    position: 'fixed' as 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}

type Props = {
  id: number
  isOpen: boolean
  onClose: () => void
}

export function KanbanCardModal(props: Props) {
  const { user } = useAuth0()
  const isSuperuser = useIsAllowed(['admin', 'superuser'])

  const cardID = props.id

  const { loading, error, data, subscribeToMore } = useQuery<
    CardModalQuery,
    CardModalQueryVariables
  >(cardModalQuery, {
    variables: {
      id: cardID,
    },
  })

  React.useEffect(() => {
    const unsub = subscribeToMore<
      CardModalSubscription,
      CardModalSubscriptionVariables
    >({
      document: cardModalSubscription,
      variables: {
        id: cardID,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const newCard = subscriptionData.data.cards_by_pk
        return {
          ...prev,
          ...(newCard || {}),
        }
      },
      onError: (err) => {
        Sentry.captureException(err)
      },
    })
    return () => {
      unsub && unsub()
    }
  }, [subscribeToMore, cardID])

  if (loading || error || !data) return null
  // TODO handle error

  const { cards_by_pk: card } = data

  if (!card) return null
  // TODO handle no data

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      style={customStyles}
      contentLabel={`Kanban Card Modal: ${card.title || '-'}`}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      shouldReturnFocusAfterClose
      className="bg-white rounded w-3/5 outline-none overflow-auto relative"
      overlayClassName="bg-gray-600 bg-opacity-75"
    >
      <UploadProvider>
        <div className="absolute right-0 top-0 mt-1 mr-1">
          {data.cards_by_pk && data.cards_by_pk.is_priority && (
            <div className="absolute top-0 right-10">🔥</div>
          )}
          <button
            type="button"
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-gray-700 hover:bg-gray-100 focus:outline-none focus:border-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 active:bg-gray-200"
            onClick={props.onClose}
          >
            <FontAwesomeIcon icon={['far', 'times']} />
          </button>
        </div>
        <div className="flex h-full overflow-y-auto">
          <div className="flex-1 p-8 overflow-x-hidden">
            <KanbanCardModalBody card={card} />
            <div className="mt-6">
              <KanbanCardModalDivider label="Time Spent" />
              <KanbanCardModalTimeTracking cardTimes={card.card_times} />
            </div>
            <div className="mt-6">
              <KanbanCardModalDivider label="Uploads" />
              <KanbanCardModalAttachments attachments={card.card_attachments} />
            </div>
            <div className="mt-6">
              <KanbanCardModalDivider label="Comments" />
              <KanbanCardModalComments
                cardID={card.id}
                comments={card.card_comments}
              />
            </div>
            <div className="mt-6">
              <KanbanCardModalDivider label="Activity" />
              <KanbanCardModalActivity
                cardID={card.id}
                activity={card.card_activity}
              />
              <p className="mt-2 text-xs">
                <span className="font-medium">Created on </span>
                {new Date(card.created_at).toLocaleString()}
              </p>
              <p className="text-xs">
                <span className="font-medium">Last edited </span>
                {card.updated_at && new Date(card.updated_at).toLocaleString()}
              </p>
            </div>
          </div>
          <div
            className="p-8 border-l border-cool-gray-300"
            style={styles.minWidth}
          >
            <div>
              <h3 className="ml-2 font-semibold">Actions</h3>
              <ul className="list-none">
                <li className="my-2">
                  <AttachFilesAction cardID={card.id} />
                </li>
                <li className="my-2">
                  <ChangeCardStateDropdown
                    cardID={card.id}
                    state={card.state}
                  />
                </li>
                {isSuperuser && (
                  <li className="my-2">
                    <ChangeCardPriorityDropdown
                      cardID={card.id}
                      is_priority={card.is_priority}
                    />
                  </li>
                )}
                <li className="my-2">
                  <CopyTaskLinkAction />
                </li>
                <li className="my-2">
                  <button
                    type="button"
                    className="w-full inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-red-700 hover:bg-red-100 focus:outline-none focus:border-red-300 focus:ring-2 focus:ring-offset-2 focus:ring-red-500 active:bg-red-200"
                  >
                    <FontAwesomeIcon
                      icon={['far', 'archive']}
                      className="-ml-0.5 mr-2 h-4 w-4"
                    />
                    Move to archive
                  </button>
                </li>
                {card.author.id === userId(user) && (
                  <li className="my-2">
                    <DeleteCardAction
                      cardID={card.id}
                      onDelete={props.onClose}
                    />
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </UploadProvider>
    </Modal>
  )
}

const styles = {
  minWidth: {
    minWidth: '256px',
  },
}
