import React from 'react'
import { Link } from 'react-router-dom'
import { Transition } from '@headlessui/react'
import OutsideClickHandler from 'react-outside-click-handler'
import { useBoolean } from '../hooks/use-boolean'
import { Avatar } from './Avatar'
import type { NavItem } from './Header'

type Props = {
  email: string
  items: NavItem[]
}

export function ProfileDropdown({ email }: Props) {
  const [isOpen, { toggle: toggleOpen }] = useBoolean(false)

  const handleOutsideClick = React.useCallback(() => {
    if (isOpen) {
      toggleOpen()
    }
  }, [isOpen, toggleOpen])

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <div className="relative">
        <div>
          <button
            onClick={toggleOpen}
            className="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out"
          >
            <Avatar email={email} tailwindSize={8} />
          </button>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
            <div className="py-1 rounded-md bg-white ring-1 ring-black ring-opacity-5">
              <Link
                to="/profile"
                className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
              >
                Your Profile
              </Link>
              <Link
                to="/settings"
                className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
              >
                Settings
              </Link>
            </div>
          </div>
        </Transition>
      </div>
    </OutsideClickHandler>
  )
}
