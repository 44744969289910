import gql from 'graphql-tag'

export const cardFragment = gql`
  fragment CardFragment on cards {
    id
    column_id
    order
    title
    description
    author {
      id
      email
      name
    }
    created_at
    updated_at
    is_public
    is_priority
    state
  }
`
