import * as yup from 'yup'
import cx from 'classnames'
import { FormikConfig, FormikValues, useFormik } from 'formik'
import {
  ClockpunchFragment,
  UpdateClockPunchMutation,
  UpdateClockPunchMutationVariables,
} from '../graphql/optitorque-kanban-graphql'
import { useBoolean } from '../hooks/use-boolean'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutation } from '@apollo/client'
import { updateClockpunchMutation } from '../graphql/mutations/updateClockpunch.mutation'

export const timeRegex = new RegExp(
  /\b((1[0-2]|0?[1-9]):([0-5][0-9]):?(?:[0-5][0-9])?\s?([AaPp][Mm]))/
)

export const timeToTimestamp = (time: string) => {
  const now = new Date()
  const isPM = !!time.match(/[Pp][Mm]/)

  let [hours, minutes, seconds] = time
    .replace(/[AaPp][Mm]/, '')
    .trim()
    .split(':')
    .map((v) => parseInt(v, 10))
  if (isPM) {
    hours += 12
  }
  now.setHours(hours)
  now.setMinutes(minutes)
  if (seconds !== undefined) {
    now.setSeconds(seconds)
  } else {
    now.setSeconds(0)
    now.setMilliseconds(0)
  }
  return now.toISOString()
}

type Props = {
  idx: number
  punch: ClockpunchFragment
  onDelete: (punchId: number) => void
}

export function TimeclockUserEditRow({ idx, punch, onDelete }: Props) {
  const [updateClockpunch] = useMutation<
    UpdateClockPunchMutation,
    UpdateClockPunchMutationVariables
  >(updateClockpunchMutation)
  const isEven = idx % 2 === 0
  const date = new Date(punch.punch_in).toLocaleDateString('en-US')
  const punchIn = new Date(punch.punch_in).toLocaleTimeString('en-US')
  const punchOut = punch.punch_out
    ? new Date(punch.punch_out).toLocaleTimeString('en-US')
    : '-'

  const handleUpdateClockpunch = (values: {
    punchIn?: string
    punchOut?: string
  }) => {
    let pIn: string
    let pOut: string

    if (!values.punchIn) {
      pIn = timeToTimestamp(punchIn)
    } else {
      pIn = timeToTimestamp(values.punchIn)
    }

    if (!values.punchOut) {
      pOut = timeToTimestamp(punchOut)
    } else {
      pOut = timeToTimestamp(values.punchOut)
    }

    updateClockpunch({
      variables: {
        id: punch.id,
        punchIn: pIn,
        punchOut: pOut,
      },
    })
  }

  const handleUpdateClockpunchDate = (values: { date: string }) => {
    const pIn = new Date(timeToTimestamp(punchIn))
    const pOut = new Date(timeToTimestamp(punchOut))
    const newDate = new Date(values.date)

    pIn.setFullYear(newDate.getFullYear())
    pIn.setMonth(newDate.getMonth())
    pIn.setDate(newDate.getDate())

    pOut.setFullYear(newDate.getFullYear())
    pOut.setMonth(newDate.getMonth())
    pOut.setDate(newDate.getDate())

    updateClockpunch({
      variables: {
        id: punch.id,
        punchIn: pIn.toISOString(),
        punchOut: pOut.toISOString(),
      },
    })
  }
  // yup provides a date() validator, but it is ISO8601 based, we may want to use a timepicker component
  // or let the user edit by hand and validate with a yup.string() regex of /\d\d:\d\d:\d\d AM|PM/
  // TODO: allow insertion of a new clockpunch entry
  // TODO: implement codes for vacation, sick day, setting to vacation defaults to a full day, 8am-4:00pm
  // and the user can edit the time ranges if the person took a half day or whatever
  // TODO: disallow editing a punch where it would overlap in time range with another punch
  const isSubmitting = false
  return (
    <tr key={punch.id} className={isEven ? 'bg-white' : 'bg-gray-50'}>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-10 text-gray-500">
        <EditableRow
          initialValues={{ date }}
          validationSchema={yup
            .date()
            .transform((value, originalValue) => {
              const timestamp = Date.parse(originalValue.date)
              if (isNaN(timestamp)) {
                return new Date('')
              }
              return new Date(timestamp)
            })
            .required()}
          onSubmit={handleUpdateClockpunchDate}
        >
          {date}
        </EditableRow>
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-10 text-gray-500">
        <EditableRow
          initialValues={{ punchIn }}
          validationSchema={yup.object().shape({
            punchIn: yup.string().required().matches(timeRegex),
          })}
          onSubmit={handleUpdateClockpunch}
        >
          {punchIn}
        </EditableRow>
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-10 text-gray-500">
        <EditableRow
          initialValues={{ punchOut }}
          validationSchema={yup.object().shape({
            punchOut: yup.string().required().matches(timeRegex),
          })}
          onSubmit={handleUpdateClockpunch}
        >
          {punchOut}
        </EditableRow>
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-10 text-gray-500"></td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-10 text-gray-500">
        <button
          type="button"
          onClick={() => onDelete(punch.id)}
          disabled={isSubmitting}
          className={cx(
            'items-center px-2 py-1 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600  focus:outline-none focus:border-red-700 focus:ring-2 focus:ring-offset-2 focus:ring-red-500 active:bg-red-700',
            {
              'hover:bg-red-500': !isSubmitting,
              'opacity-50 cursor-not-allowed': isSubmitting,
            }
          )}
        >
          <FontAwesomeIcon icon={['far', 'trash-alt']} />
        </button>
      </td>
    </tr>
  )
}

type EditableRowProps<Values> = {
  children: React.ReactNode
} & Pick<
  FormikConfig<Values>,
  'onSubmit' | 'validationSchema' | 'initialValues'
>

export function EditableRow<Values extends FormikValues>({
  initialValues,
  onSubmit,
  validationSchema,
  children,
}: EditableRowProps<Values>) {
  const formikProps = useFormik<Values>({
    initialValues,
    onSubmit: (values, helpers) => {
      onSubmit(values, helpers)
      setEditing.off()
    },
    validationSchema,
  })
  const [isEditing, setEditing] = useBoolean(false)

  if (!isEditing) {
    return (
      <div
        onClick={() => setEditing.on()}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            setEditing.on()
          }
        }}
        tabIndex={0}
      >
        {children}
      </div>
    )
  }

  return (
    <form className="inline" onSubmit={formikProps.handleSubmit}>
      {Object.keys(formikProps.values).map((key) => {
        const fieldProps = formikProps.getFieldProps(key)
        const handleBlur = (e: React.FocusEvent) => {
          formikProps.handleSubmit()
        }
        return (
          <input
            key={key}
            type="text"
            autoFocus
            {...fieldProps}
            onBlur={handleBlur}
            className={cx(
              'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md focus:outline-none',
              {
                'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500':
                  formikProps.touched &&
                  Object.keys(formikProps.errors).length > 0,
              }
            )}
          />
        )
      })}
    </form>
  )
}
