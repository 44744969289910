import gql from 'graphql-tag'
import { cardFragment } from '../fragments/card.fragment'

export const updateCardMutation = gql`
  mutation UpdateCardMutation(
    $input: cards_set_input!
    $ids: cards_pk_columns_input!
  ) {
    update_cards_by_pk(_set: $input, pk_columns: $ids) {
      ...CardFragment
    }
  }
  ${cardFragment}
`
