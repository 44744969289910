import React from 'react'
import { useQuery } from '@apollo/client'
import {
  getDay,
  differenceInHours,
  differenceInMinutes,
  subHours,
  format,
} from 'date-fns/esm'
import {
  TeamClockpunchesQuery,
  TeamClockpunchesQueryVariables,
  TeamClockpunchesQuery_users,
} from '../graphql/optitorque-kanban-graphql'
import { teamClockpunchesQuery } from '../graphql/queries/teamClockpunches.query'
import { AppPage } from './AppPage'
import { Avatar } from './Avatar'
import { Link } from 'react-router-dom'
import { useWeek } from '../hooks/use-week'
import { TimeclockDatePicker } from './TimeclockDatePicker'
import { useSyncWeekToQueryString } from '../hooks/use-sync-week-to-querystring'

export function TimeclockPage() {
  const { week, gotoPrevWeek, gotoNextWeek } = useWeek()
  const { data, error } = useQuery<
    TeamClockpunchesQuery,
    TeamClockpunchesQueryVariables
  >(teamClockpunchesQuery, {
    variables: {
      startOfWeek: week.startOfWeek,
      endOfWeek: week.endOfWeek,
    },
    fetchPolicy: 'cache-and-network',
  })

  useSyncWeekToQueryString(week.startOfWeek)

  return (
    <AppPage>
      {error ? null : (
        <div className="h-full w-full bg-cool-gray-100">
          <div className="sm:px-6 lg:px-8">
            <TimeclockDatePicker
              week={week}
              gotoPrevWeek={gotoPrevWeek}
              gotoNextWeek={gotoNextWeek}
            />
            <div className="flex flex-col max-w-7xl mx-auto">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Name
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Mon
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Tue
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Wed
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Thu
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Fri
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Sat
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Sun
                          </th>
                          <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Total
                          </th>
                          <th className="pr-6 py-3 bg-gray-50"></th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {data?.users.map((u) => (
                          <UserClockpunchRow
                            key={u.id}
                            user={u}
                            weekStart={week.startOfWeek}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </AppPage>
  )
}

type Props = {
  user: TeamClockpunchesQuery_users
  weekStart: string
}

export function UserClockpunchRow({ user, weekStart }: Props) {
  const decimalHours = user.clockpunches.reduce(
    (acc, punch) => {
      if (!punch || !punch.punch_out) return acc
      const pOut = new Date(punch.punch_out)
      const pIn = new Date(punch.punch_in)
      const diffHours = differenceInHours(pOut, pIn)
      const diffMinutes = differenceInMinutes(subHours(pOut, diffHours), pIn)
      const decimalTime = parseFloat(
        timeToDecimal(`${diffHours}:${diffMinutes}`)
      )
      const day = getDay(pIn)
      switch (day) {
        case 0:
          acc.sun += decimalTime
          break
        case 1:
          acc.mon += decimalTime
          break
        case 2:
          acc.tue += decimalTime
          break
        case 3:
          acc.wed += decimalTime
          break
        case 4:
          acc.thu += decimalTime
          break
        case 5:
          acc.fri += decimalTime
          break
        case 6:
          acc.sat += decimalTime
          break
        default:
      }
      acc.total = acc.total + decimalTime
      return acc
    },
    {
      total: 0,
      mon: 0,
      tue: 0,
      wed: 0,
      thu: 0,
      fri: 0,
      sat: 0,
      sun: 0,
    }
  )

  return (
    <tr>
      <td className="px-6 py-4 whitespace-no-wrap">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            <Avatar email={user.email} tailwindSize={10} />
          </div>
          <div className="ml-4">
            <div className="text-sm leading-5 font-medium text-gray-900">
              {user.name}
            </div>
            <div className="text-sm leading-5 text-gray-500">{user.email}</div>
          </div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        {decimalHours.mon.toFixed(2)}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        {decimalHours.tue.toFixed(2)}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        {decimalHours.wed.toFixed(2)}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        {decimalHours.thu.toFixed(2)}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        {decimalHours.fri.toFixed(2)}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        {decimalHours.sat.toFixed(2)}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        {decimalHours.sun.toFixed(2)}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
        {decimalHours.total.toFixed(2)}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
        <Link
          className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          to={`/timeclock/${user.dbid}/edit?week=${format(
            new Date(weekStart),
            'yyyy-MM-dd'
          )}`}
        >
          Edit
        </Link>
      </td>
    </tr>
  )
}

function timeToDecimal(t: string) {
  let arr = t.split(':')
  let dec = (parseInt(arr[1], 10) / 6) * 10

  return parseFloat(
    parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec
  ).toFixed(2)
}
