import gql from 'graphql-tag'
import { userFragment } from './user.fragment'

export const cardAttachmentFragment = gql`
  fragment CardAttachmentFragment on card_attachment {
    id
    signed_id
    size
    type
    name
    created_at
    author {
      ...UserFragment
    }
  }
  ${userFragment}
`
