import gql from 'graphql-tag'
import { clockpunchFragment } from '../fragments/clockpunch.fragment'
import { teamFragment } from '../fragments/team.fragment'

export const teamClockpunchesQuery = gql`
  query TeamClockpunchesQuery(
    $startOfWeek: timestamptz!
    $endOfWeek: timestamptz!
  ) {
    users {
      ...TeamFragment
      clockpunches(
        order_by: { punch_out: asc_nulls_last }
        where: { punch_in: { _gte: $startOfWeek, _lt: $endOfWeek } }
      ) {
        ...ClockpunchFragment
      }
    }
  }
  ${teamFragment}
  ${clockpunchFragment}
`
