import gql from 'graphql-tag'
import { meFragment } from '../fragments/me.fragment'

export const updateUserMutation = gql`
  mutation UpdateUserMutation(
    $id: Int!
    $name: String!
    $title: String!
    $phone: String!
  ) {
    update_users_by_pk(
      pk_columns: { dbid: $id }
      _set: { name: $name, title: $title, phone: $phone }
    ) {
      ...MeFragment
    }
  }
  ${meFragment}
`
