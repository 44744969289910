import gql from 'graphql-tag'

export const meFragment = gql`
  fragment MeFragment on users {
    id
    name
    email
    title
    phone
    dbid
  }
`
