import gql from 'graphql-tag'
import { cardTimeFragment } from '../fragments/cardTime.fragment'

export const insertCardTimeMutation = gql`
  mutation InsertCardTimeMutation($input: card_time_insert_input!) {
    insert_card_time_one(object: $input) {
      ...CardTimeFragment
    }
  }
  ${cardTimeFragment}
`
