import React from 'react'
import prettyBytes from 'pretty-bytes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UploadStateContext } from '../hooks/use-upload'
import { CardAttachmentFragment } from '../graphql/optitorque-kanban-graphql'
import { SPACES_CDN_URL } from '../constants'

type Props = {
  attachments: CardAttachmentFragment[]
}

export function KanbanCardModalAttachments({ attachments }: Props) {
  const state = React.useContext(UploadStateContext)

  if (!attachments.length) return null

  return (
    <ul className="border border-gray-200 rounded-md text-sm leading-5 text-gray-900">
      {attachments.map((attachment, idx) => (
        <li
          key={attachment.id}
          className={`pl-3 pr-4 py-3 flex items-center justify-between ${
            idx > 0 ? 'border-t border-gray-200' : ''
          }`}
        >
          <div className="w-0 flex-1 flex items-center">
            <FontAwesomeIcon
              icon={['far', 'paperclip']}
              className="flex-shrink-0 h-5 w-5 text-gray-400"
            />
            <span className="ml-2 flex-1 w-0 truncate">
              {attachment.name}
              <span className="ml-2 text-xs text-gray-400">
                ({prettyBytes(attachment.size)})
              </span>
            </span>
          </div>
          <div className="ml-4 flex-shrink-0">
            <a
              href={`${SPACES_CDN_URL}/${attachment.signed_id}`}
              download
              target="_blank"
              rel="noopener noreferrer"
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              Download
            </a>
          </div>
        </li>
      ))}
      {state.loading && (
        <li className="pl-3 pr-4 py-3 flex items-center justify-between border-t border-gray-200">
          <div className="shadow w-full bg-gray-200 rounded-sm">
            <div
              className="bg-blue-600 text-xs leading-none py-1 text-center text-white rounded-l-sm transition-all linear duration-75"
              style={{ width: `${state.progress}%` }}
            >
              {state.progress}%
            </div>
          </div>
        </li>
      )}
    </ul>
  )
}
