import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useIsAllowed } from '../hooks/use-is-allowed'

type Props = RouteProps & {
  allowedRoles: string[]
}

export function PrivateRoute({ children, allowedRoles, ...rest }: Props) {
  let isAllowed = useIsAllowed(allowedRoles)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAllowed ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/not-allowed',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
