import { useMutation, useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from 'react-modal'
import {
  DeleteClockpunchMutation,
  DeleteClockpunchMutationVariables,
  MeQuery,
  MeQueryVariables,
} from '../graphql/optitorque-kanban-graphql'
import { deleteClockpunchMutation } from '../graphql/mutations/deleteClockpunch.mutation'
import { useAuth0 } from '@auth0/auth0-react'
import { meQuery } from '../graphql/queries/me.query'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    position: 'fixed' as 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}

type Props = {
  isOpen: boolean
  punchId: number
  onRequestClose: () => void
}

export function DeletePunchModal({ isOpen, punchId, onRequestClose }: Props) {
  const { user } = useAuth0()
  const { data } = useQuery<MeQuery, MeQueryVariables>(meQuery, {
    variables: {
      email: user.email,
    },
  })
  const [deleteClockpunch, { loading: deleteLoading }] = useMutation<
    DeleteClockpunchMutation,
    DeleteClockpunchMutationVariables
  >(deleteClockpunchMutation, {
    variables: {
      id: punchId,
    },
  })

  const handleDelete = () => {
    const user = data?.users[0]
    deleteClockpunch({
      update: (cache) => {
        if (!user) return
        cache.modify({
          id: `${user.__typename}:${user.id}`,
          fields: {
            clockpunches(existingClockpunches = [], { readField }) {
              return existingClockpunches.filter(
                (clockpunchRef: any) =>
                  punchId !== readField('id', clockpunchRef)
              )
            },
          },
        })
      },
    }).then(() => onRequestClose())
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Delete Clockpunch"
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      shouldReturnFocusAfterClose
      className="absolute outline-none"
      overlayClassName="bg-gray-600 bg-opacity-75"
    >
      <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <FontAwesomeIcon
              icon={['fal', 'exclamation-triangle']}
              className="h-6 w-6 text-red-600"
            />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3
              className="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              Delete time punch
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Are you sure you want to delete this time punch?
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            disabled={deleteLoading}
            onClick={handleDelete}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Delete
          </button>
          <button
            type="button"
            onClick={onRequestClose}
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  )
}
