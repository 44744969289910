import { format } from 'date-fns/esm'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'

/**
 * Syncs the week to the querystring as `week=YYYY-MM-DD`
 * and leaves other query string parameters intact.
 *
 * @param week a date string
 */
export function useSyncWeekToQueryString(week: string) {
  let history = useHistory()
  let search = useLocation().search
  let newQSWeek = format(new Date(week), 'yyyy-MM-dd')

  useEffect(() => {
    let params = new URLSearchParams(search)
    if (params.get('week') !== newQSWeek) {
      params.set('week', newQSWeek)
      history.push({ search: params.toString() })
    }
  }, [history, search, newQSWeek])
}
