import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type Props = {
  title: string
  onOpenCardForm: () => void
}

export const KanbanColumnHeader = ({ title, onOpenCardForm }: Props) => {
  return (
    <header className="flex justify-between -mb-1 px-4">
      <h2 className="text-2xl leading-loose text-gray-900">{title}</h2>
      <div className="flex items-center justify-center">
        <button
          type="button"
          onClick={onOpenCardForm}
          data-testid="open-card-form-button"
          className="inline-flex items-center cursor-pointer rounded border px-2 py-1 border-transparent bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 focus:border-indigo-600 active:bg-indigo-600"
        >
          <FontAwesomeIcon icon={['fal', 'plus']} color="#fff" />
        </button>
      </div>
    </header>
  )
}
