import gql from 'graphql-tag'
import { teamFragment } from '../fragments/team.fragment'

export const teamQuery = gql`
  query TeamQuery {
    users {
      ...TeamFragment
    }
  }
  ${teamFragment}
`
