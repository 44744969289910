import React from 'react'
import * as Sentry from '@sentry/browser'
import { differenceInSeconds } from 'date-fns'
import OutsideClickHandler from 'react-outside-click-handler'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutation, useApolloClient } from '@apollo/client'
import { Transition } from '@headlessui/react'
import { useBoolean } from '../hooks/use-boolean'
import { latestCardTimeQuery } from '../graphql/queries/latestCardTime.query'
import { insertCardTimeMutation } from '../graphql/mutations/insertCardTime.mutation'
import { updateCardTimeMutation } from '../graphql/mutations/updateCardTime.mutation'
import { updateCardStateMutation } from '../graphql/mutations/updateCardState.mutation'
import { cardModalFragment } from '../graphql/fragments/cardModal.fragment'
import {
  card_state_enum,
  UpdateCardStateMutation,
  UpdateCardStateMutationVariables,
  InsertCardTimeMutation,
  InsertCardTimeMutationVariables,
  LatestCardTimeQuery,
  LatestCardTimeQueryVariables,
  UpdateCardTimeMutation,
  UpdateCardTimeMutationVariables,
  CardModalFragment,
  InsertCardActivityMutation,
  InsertCardActivityMutationVariables,
} from '../graphql/optitorque-kanban-graphql'
import { parseUTCDate } from '../time'
import { CardStateIndicator } from './CardStateIndicator'
import { insertCardActivityMutation } from '../graphql/mutations/insertCardActivity.mutation'

type Props = {
  cardID: number
  state: card_state_enum
}

const cardStateToDesc = {
  [card_state_enum.BLUE]: 'Complete',
  [card_state_enum.GREEN]: 'In Progress',
  [card_state_enum.YELLOW]: 'On Hold',
  [card_state_enum.RED]: 'Blocked',
}

export function ChangeCardStateDropdown({ cardID, state }: Props) {
  const [isOpen, { toggle: toggleOpen }] = useBoolean(false)

  const [updateCard] = useMutation<
    UpdateCardStateMutation,
    UpdateCardStateMutationVariables
  >(updateCardStateMutation)

  const [insertCardTime] = useMutation<
    InsertCardTimeMutation,
    InsertCardTimeMutationVariables
  >(insertCardTimeMutation)

  const [updateCardTime] = useMutation<
    UpdateCardTimeMutation,
    UpdateCardTimeMutationVariables
  >(updateCardTimeMutation)

  const [insertCardActivity] = useMutation<
    InsertCardActivityMutation,
    InsertCardActivityMutationVariables
  >(insertCardActivityMutation)

  const client = useApolloClient()

  const changeCardState = async (newState: card_state_enum) => {
    let prevState = state

    toggleOpen()

    let now = new Date()
    let nowISOString = now.toISOString()
    let activityInserted = false

    try {
      updateCard({
        variables: {
          ids: {
            id: cardID,
          },
          input: {
            state: newState,
          },
        },
        optimisticResponse: {
          update_cards_by_pk: {
            id: cardID,
            state: newState,
            __typename: 'cards',
          },
        },
      })
        .then(({ errors }) => {
          if (errors?.length) {
            Sentry.withScope((scope) => {
              scope.setExtra('GraphQLErrors', errors)
              scope.setLevel(Sentry.Severity.Error)
              Sentry.captureMessage(
                `updateCard(id=${cardID}, state=${newState}) failed`
              )
            })
          }
        })
        .catch(Sentry.captureException)

      if (
        [
          card_state_enum.RED,
          card_state_enum.YELLOW,
          card_state_enum.BLUE,
        ].includes(prevState) &&
        newState === card_state_enum.GREEN
      ) {
        let { errors } = await insertCardTime({
          variables: {
            input: {
              start: nowISOString,
              stop: nowISOString,
              card_id: cardID,
            },
          },
          update: (cache, { data }) => {
            let cardTime = data?.insert_card_time_one

            if (!cardTime) return

            try {
              let card = cache.readFragment<CardModalFragment>({
                fragment: cardModalFragment,
                fragmentName: 'CardModalFragment',
                id: `cards:${cardID}`,
              })

              if (!card) return

              cache.writeFragment<CardModalFragment>({
                fragment: cardModalFragment,
                fragmentName: 'CardModalFragment',
                id: `cards:${cardID}`,
                data: {
                  ...card,
                  card_times: [...card.card_times, cardTime],
                },
              })
            } catch (err) {
              Sentry.captureException(err)
            }
          },
        })

        if (errors?.length) {
          Sentry.withScope((scope) => {
            scope.setExtra('GraphQLErrors', errors)
            scope.setLevel(Sentry.Severity.Error)
            Sentry.captureMessage(`insertCard(state=${newState}) failed`)
          })
        } else {
          insertCardActivity({
            variables: {
              input: {
                card_id: cardID,
                action: `changed the card state to ${cardStateToDesc[newState]} and started working on this card`,
              },
            },
          })
          activityInserted = true
        }

        return
      }

      if (
        prevState === card_state_enum.GREEN &&
        [
          card_state_enum.RED,
          card_state_enum.YELLOW,
          card_state_enum.BLUE,
        ].includes(newState)
      ) {
        let { data, errors } = await client.query<
          LatestCardTimeQuery,
          LatestCardTimeQueryVariables
        >({
          query: latestCardTimeQuery,
          variables: {
            cardId: cardID,
          },
          fetchPolicy: 'network-only',
        })

        if (!data?.card_time.length) {
          Sentry.withScope((scope) => {
            scope.setExtra('GraphQLErrors', errors)
            scope.setLevel(Sentry.Severity.Error)
            Sentry.captureMessage(`latestCardTime(cardID=${cardID}) failed`)
          })

          return
        }

        let startUTC = parseUTCDate(data.card_time[0].start)
        let diff = differenceInSeconds(now.getTime(), startUTC)

        updateCardTime({
          variables: {
            id: data.card_time[0].id,
            stop: nowISOString,
          },
          optimisticResponse: {
            update_card_time_by_pk: {
              id: data.card_time[0].id,
              stop: nowISOString,
              diff: diff,
              __typename: 'card_time',
            },
          },
          update: (cache, { data }) => {
            let cardTime = data?.update_card_time_by_pk

            if (!cardTime) return

            try {
              let card = cache.readFragment<CardModalFragment>({
                fragment: cardModalFragment,
                fragmentName: 'CardModalFragment',
                id: `cards:${cardID}`,
              })

              if (!card) return

              let updatedCardTimes = card.card_times.map((t) => {
                if (t.id === cardTime!.id) {
                  return {
                    ...t,
                    ...cardTime,
                  }
                }
                return t
              })

              cache.writeFragment<CardModalFragment>({
                fragment: cardModalFragment,
                fragmentName: 'CardModalFragment',
                id: `cards:${cardID}`,
                data: {
                  ...card,
                  card_times: updatedCardTimes,
                },
              })
            } catch (err) {
              Sentry.captureException(err)
            }
          },
        })
          .then(({ errors }) => {
            if (errors?.length) {
              Sentry.withScope((scope) => {
                scope.setExtra('GraphQLErrors', errors)
                scope.setLevel(Sentry.Severity.Error)
                Sentry.captureMessage(
                  `updateCardTime(id=${
                    data?.card_time[0].id
                  }, stop=${now.toISOString()}) failed`
                )
              })
            }
          })
          .catch(Sentry.captureException)

        insertCardActivity({
          variables: {
            input: {
              card_id: cardID,
              action: `changed the card state to ${cardStateToDesc[newState]} and stopped working on this card`,
            },
          },
        })
        activityInserted = true
      }

      if (!activityInserted) {
        insertCardActivity({
          variables: {
            input: {
              card_id: cardID,
              action: `changed the card state to ${cardStateToDesc[newState]}`,
            },
          },
        })
      }
    } catch (err) {
      Sentry.captureException(err)
    }
  }

  const handleOutsideClick = React.useCallback(() => {
    if (isOpen) {
      toggleOpen()
    }
  }, [isOpen, toggleOpen])

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <div className="relative">
        <div>
          <button
            type="button"
            onClick={toggleOpen}
            className="w-full inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-indigo-700 hover:bg-indigo-100 focus:outline-none focus:border-indigo-300 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 active:bg-indigo-200 transition ease-in-out duration-150"
          >
            <FontAwesomeIcon
              icon={['far', 'traffic-light']}
              className="-ml-0.5 mr-2 h-4 w-4"
            />
            Change Status
          </button>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
            <div className="flex flex-col py-1 rounded-md bg-white ring-1 ring-black ring-opacity-5">
              <button
                type="button"
                onClick={() => changeCardState(card_state_enum.BLUE)}
                className="flex items-center flex-1 px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
              >
                <CardStateIndicator
                  size={3}
                  state={card_state_enum.BLUE}
                  className="block mr-2"
                />
                Complete
              </button>
              <button
                type="button"
                onClick={() => changeCardState(card_state_enum.GREEN)}
                className="flex items-center flex-1 px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
              >
                <CardStateIndicator
                  size={3}
                  state={card_state_enum.GREEN}
                  className="block mr-2"
                />
                In Progress
              </button>
              <button
                type="button"
                onClick={() => changeCardState(card_state_enum.YELLOW)}
                className="flex items-center flex-1 px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
              >
                <CardStateIndicator
                  size={3}
                  state={card_state_enum.YELLOW}
                  className="block mr-2"
                />
                On Hold
              </button>
              <button
                type="button"
                onClick={() => changeCardState(card_state_enum.RED)}
                className="flex items-center flex-1 px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
              >
                <CardStateIndicator
                  size={3}
                  state={card_state_enum.RED}
                  className="block mr-2"
                />
                Blocked
              </button>
            </div>
          </div>
        </Transition>
      </div>
    </OutsideClickHandler>
  )
}
