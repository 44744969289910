import React from 'react'
import Spinner from 'react-spinkit'
import { Redirect } from 'react-router'
import { useAuth0 } from '@auth0/auth0-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function LoginPage() {
  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    error,
    logout,
  } = useAuth0()
  if (isLoading) {
    return (
      <div className="h-screen w-screen flex flex-col items-center pt-24 bg-gray-200">
        <Spinner name="ball-scale-ripple" color="#667eea" fadeIn="none" />
        <h1 className="text-2xl mt-4">Loading</h1>
      </div>
    )
  }

  if (isAuthenticated) {
    return <Redirect to={`/${window.location.search}`} push={false} />
  }

  return (
    <div className="flex justify-center pt-24 bg-gray-200 h-screen">
      <div className="w-full max-w-xs">
        <img
          className="mx-auto w-auto"
          src="/images/icons/icon-128x128.png"
          alt="CPS Logo"
        />
        <h1 className="text-gray-900 mt-2 text-center font-extrabold text-3xl leading-9 mb-8">
          CPS Tracker
        </h1>
        <div className="flex flex-col justify-center items-center space-x-16">
          <span className="inline-flex rounded-md shadow-sm">
            <button
              type="button"
              onClick={() =>
                loginWithRedirect({
                  appState: {
                    returnTo: `${window.location.origin}${window.location.search}`,
                  },
                })
              }
              className="inline-flex items-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 active:bg-indigo-700"
            >
              Sign In
            </button>
          </span>
          {error && (
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() =>
                logout({
                  returnTo: `${window.location.origin}/login`,
                })
              }
            >
              <FontAwesomeIcon
                icon={['fas', 'sign-out-alt']}
                color="#fff"
                className="mr-2"
              />
              <span>Sign Out</span>
            </button>
          )}
          <p className="text-center text-gray-500 text-xs mt-4">
            &copy;{new Date().getFullYear()} OptiTorque Inc. All rights
            reserved.
          </p>
        </div>
      </div>
    </div>
  )
}
