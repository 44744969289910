import React from 'react'
import cx from 'classnames'
import { Draggable } from 'react-beautiful-dnd'
import { card_state_enum } from '../graphql/optitorque-kanban-graphql'
import { Avatar } from './Avatar'
import { CardStateIndicator } from './CardStateIndicator'
import styles from './KanbanCard.module.css'

type Props = {
  cardId: number
  index: number
  title: string
  description: string
  email: string
  state: card_state_enum
  is_priority: boolean
  onClick: (cardID: number) => void
}

export function KanbanCard({
  cardId,
  index,
  title,
  description,
  email,
  state,
  is_priority,
  onClick,
}: Props) {
  const handleCardClick = () => {
    onClick(cardId)
  }

  const cardClasses = cx(
    'cursor-pointer border border-gray-400 bg-white rounded p-3 mt-2 relative',
    {
      'bg-red-200 border-red-400': state === card_state_enum.RED,
    }
  )
  return (
    <Draggable draggableId={`${cardId}`} index={index}>
      {(provided) => (
        <article
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={cardClasses}
          ref={provided.innerRef}
          onClick={handleCardClick}
        >
          <header
            className="flex items-center pb-3 border-b border-dashed border-gray-600 cursor-pointer hover:underline"
            style={{ textDecorationColor: '#667eea' }}
          >
            <Avatar email={email} />
            <h3 className="text-base leading-normal text-indigo-900 ml-3 flex-auto">
              {title}
            </h3>
            <CardStateIndicator
              state={state}
              size={2}
              className={`flex-shrink`}
            />
          </header>
          <p
            className={`text-sm leading-tight text-black mt-3 whitespace-pre-wrap ${styles.cardDescription}`}
          >
            {description}
          </p>
          {is_priority && (
            <div className="absolute bottom-1.5 right-1.5">🔥</div>
          )}
        </article>
      )}
    </Draggable>
  )
}
