import gql from 'graphql-tag'

export const teamFragment = gql`
  fragment TeamFragment on users {
    id
    dbid
    name
    email
    title
    phone
  }
`
