import React from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import * as Sentry from '@sentry/browser'
import { useMutation } from '@apollo/client'
import { updateUserMutation } from '../graphql/mutations/updateUser.mutation'
import {
  MeFragment,
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from '../graphql/optitorque-kanban-graphql'

const profileSchema = yup.object({
  name: yup.string(),
  title: yup.string(),
  phone: yup.string(),
})

type Props = {
  user: MeFragment
}

export function Profile({ user }: Props) {
  const [updateUser] = useMutation<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >(updateUserMutation)

  const formik = useFormik({
    initialValues: {
      name: user.name ?? '',
      title: user.title ?? '',
      phone: user.phone ?? '',
    },
    validationSchema: profileSchema,
    onSubmit: async (values) => {
      try {
        let { errors } = await updateUser({
          variables: {
            id: user.dbid,
            name: values.name,
            title: values.title,
            phone: values.phone,
          },
        })

        if (errors?.length) {
          Sentry.withScope((scope) => {
            scope.setExtra('graphqlerrors', errors)
            scope.setLevel(Sentry.Severity.Error)
            Sentry.captureMessage('updateUser() failed')
          })
        }
      } catch (e) {
        Sentry.captureException(e)
      }
    },
  })

  return (
    <div className="h-full w-full bg-cool-gray-100 py-8">
      <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 bg-white">
        <form onSubmit={formik.handleSubmit}>
          <div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Profile
              </h3>
              <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                This information will be displayed to the company so be careful
                what you share.
              </p>
            </div>
            <div className="mt-6 sm:mt-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="email"
                  className="block text-sm leading-6 font-medium text-gray-700 sm:mt-px"
                >
                  Email address
                </label>
                <div className="sm:mt-0 sm:col-span-2">
                  <div className="max-w-lg">
                    <span className="min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300">
                      {user.email}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="photo"
                className="block text-sm font-medium text-gray-700"
              >
                Photo
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="flex items-center">
                  <span className="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                    <svg
                      className="h-full w-full text-gray-300"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </span>
                  <span className="ml-5 rounded-md shadow-sm">
                    <button
                      type="button"
                      className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Change
                    </button>
                  </span>
                </div>
              </div>
            </div>

            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Name
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Title
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    id="title"
                    name="title"
                    type="text"
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    onChange={formik.handleChange}
                    value={formik.values.title}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Phone
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    id="phone"
                    name="phone"
                    type="tel"
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="mt-8 border-t border-gray-200 pt-8 sm:mt-5 sm:pt-10">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Notifications
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                      We'll always let you know about important changes, but you
                      pick what else you want to hear about.
                    </p>
                  </div>
                  <div className="mt-6 sm:mt-5">
                    <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                      <div role="group" aria-labelledby="label-email">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                          <div>
                            <div
                              className="text-base leading-6 font-medium text-gray-900 sm:text-sm sm:leading-5 sm:text-gray-700"
                              id="label-email"
                            >
                              By Email
                            </div>
                          </div>
                          <div className="mt-4 sm:mt-0 sm:col-span-2">
                            <div className="max-w-lg">
                              <div className="relative flex items-start">
                                <div className="flex items-center h-5">
                                  <input
                                    id="comments"
                                    type="checkbox"
                                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                  />
                                </div>
                                <div className="ml-3 text-sm leading-5">
                                  <label
                                    htmlFor="comments"
                                    className="font-medium text-gray-700"
                                  >
                                    Comments
                                  </label>
                                  <p className="text-gray-500">
                                    Get notified when someones posts a comment
                                    on a card.
                                  </p>
                                </div>
                              </div>
                              <div className="mt-4">
                                <div className="relative flex items-start">
                                  <div className="flex items-center h-5">
                                    <input
                                      id="candidates"
                                      type="checkbox"
                                      className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                    />
                                  </div>
                                  <div className="ml-3 text-sm leading-5">
                                    <label
                                      htmlFor="candidates"
                                      className="font-medium text-gray-700"
                                    >
                                      Candidates
                                    </label>
                                    <p className="text-gray-500">
                                      Get notified when a candidate applies for
                                      a job.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-4">
                                <div className="relative flex items-start">
                                  <div className="flex items-center h-5">
                                    <input
                                      id="offers"
                                      type="checkbox"
                                      className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                    />
                                  </div>
                                  <div className="ml-3 text-sm leading-5">
                                    <label
                                      htmlFor="offers"
                                      className="font-medium text-gray-700"
                                    >
                                      Offers
                                    </label>
                                    <p className="text-gray-500">
                                      Get notified when a candidate accepts or
                                      rejects an offer.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 sm:mt-5 sm:border-t sm:border-gray-200 sm:pt-5">
                      <div role="group" aria-labelledby="label-notifications">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                          <div>
                            <div
                              className="text-base leading-6 font-medium text-gray-900 sm:text-sm sm:leading-5 sm:text-gray-700"
                              id="label-notifications"
                            >
                              Push Notifications
                            </div>
                          </div>
                          <div className="sm:col-span-2">
                            <div className="max-w-lg">
                              <p className="text-sm leading-5 text-gray-500">
                                These are delivered via SMS to your mobile
                                phone.
                              </p>
                              <div className="mt-4">
                                <div className="flex items-center">
                                  <input
                                    id="push_everything"
                                    name="form-input push_notifications"
                                    type="radio"
                                    className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                  />
                                  <label
                                    htmlFor="push_everything"
                                    className="ml-3"
                                  >
                                    <span className="block text-sm leading-5 font-medium text-gray-700">
                                      Everything
                                    </span>
                                  </label>
                                </div>
                                <div className="mt-4 flex items-center">
                                  <input
                                    id="push_email"
                                    name="form-input push_notifications"
                                    type="radio"
                                    className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                  />
                                  <label htmlFor="push_email" className="ml-3">
                                    <span className="block text-sm leading-5 font-medium text-gray-700">
                                      Same as email
                                    </span>
                                  </label>
                                </div>
                                <div className="mt-4 flex items-center">
                                  <input
                                    id="push_nothing"
                                    name="form-input push_notifications"
                                    type="radio"
                                    className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                  />
                                  <label
                                    htmlFor="push_nothing"
                                    className="ml-3"
                                  >
                                    <span className="block text-sm leading-5 font-medium text-gray-700">
                                      No push notifications
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>*/}
          <div className="mt-8 border-t border-gray-200 pt-5">
            <div className="flex justify-start">
              <span className="inline-flex rounded-md shadow-sm">
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  disabled={formik.isSubmitting}
                >
                  Save
                </button>
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
