import React from 'react'
import cx from 'classnames'
import { useLocation } from 'react-router'
import { useAuth0 } from '@auth0/auth0-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useBoolean } from '../hooks/use-boolean'
import { NavLink } from './NavLink'
import { MobileMenu } from './MobileMenu'
import { ProfileDropdown } from './ProfileDropdown'
import { MobileMenuButton } from './MobileMenuButton'
import { useIsAllowed } from '../hooks/use-is-allowed'

export type NavItem = {
  to: string
  label: string
}

const publicHeaderNavItems: NavItem[] = [
  {
    to: '/',
    label: 'Tasks',
  },
  {
    to: '/team',
    label: 'Team',
  },
]

const timeclockNavItems: NavItem[] = [
  {
    to: '/timeclock',
    label: 'Timeclock',
  },
]

const adminNavItems: NavItem[] = []

const profileNavItems: NavItem[] = [
  {
    to: '/profile',
    label: 'Your Profile',
  },
  {
    to: '/settings',
    label: 'Settings',
  },
]

export function Header() {
  const { user, logout } = useAuth0()
  const { pathname } = useLocation()
  const [isOpen, { toggle: toggleOpen }] = useBoolean(false)
  const isAdmin = useIsAllowed(['admin', 'superuser'])
  const isTimeclockEditor = useIsAllowed(['timeclock_editor'])
  let headerNavItems = publicHeaderNavItems
  if (isAdmin) {
    headerNavItems = [...headerNavItems, ...adminNavItems]
  }
  if (isAdmin || isTimeclockEditor) {
    headerNavItems = [...headerNavItems, ...timeclockNavItems]
  }

  return (
    <header>
      <nav className="bg-gray-800">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <MobileMenuButton isOpen={isOpen} onClick={toggleOpen} />
              <div className="flex-shrink-0 flex items-center">
                <img
                  className="block lg:hidden h-8 w-auto"
                  src="/images/icons/icon-72x72.png"
                  alt="CPS Logo"
                />
                <img
                  className="hidden lg:block h-8 w-auto"
                  src="/images/icons/icon-72x72.png"
                  alt="CPS Logo"
                />
                <h2 className="text-xl text-white leading-snug">CPS</h2>
              </div>
              <div className="hidden md:ml-6 md:flex md:items-center">
                {headerNavItems.map(({ to, label }, idx) => {
                  const first = cx({ 'ml-4': idx !== 0 })
                  return (
                    <NavLink
                      key={to}
                      to={to}
                      label={label}
                      active={pathname === to}
                      className={`text-sm leading-5 ${first}`}
                    />
                  )
                })}
              </div>
            </div>
            <div className="flex items-center">
              <div className="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
                <button className="p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-gray-300 focus:outline-none focus:text-gray-500 focus:bg-gray-100">
                  <svg
                    className="h-6 w-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                </button>

                <div className="ml-3">
                  <ProfileDropdown email={user.email} items={profileNavItems} />
                </div>

                <div className="flex-shrink-0 ml-3">
                  <span className="rounded-md shadow-sm">
                    <button
                      type="button"
                      className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 focus:border-indigo-600 active:bg-indigo-600"
                      onClick={() =>
                        logout({
                          returnTo: `${window.location.origin}/login`,
                        })
                      }
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'sign-out-alt']}
                        color="#fff"
                        className="mr-2"
                      />
                      <span>Sign Out</span>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <MobileMenu
          isOpen={isOpen}
          user={user}
          headerNavItems={headerNavItems}
          profileNavItems={profileNavItems}
        />
      </nav>
    </header>
  )
}
