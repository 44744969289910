import React from 'react'
import * as Sentry from '@sentry/browser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useBoolean } from '../hooks/use-boolean'
import { useMutation } from '@apollo/client'
import { softDeleteCardMutation } from '../graphql/mutations/softDeleteCard.mutation'
import {
  SoftDeleteCardMutation,
  SoftDeleteCardMutationVariables,
} from '../graphql/optitorque-kanban-graphql'

type Props = {
  cardID: number
  onDelete: () => void
}

export function DeleteCardAction({ cardID, onDelete }: Props) {
  const [isOpen, { on, off }] = useBoolean(false)

  const [deleteText, setDeleteText] = React.useState('')

  let isDisabled = deleteText !== 'delete'

  let [deleteCard] = useMutation<
    SoftDeleteCardMutation,
    SoftDeleteCardMutationVariables
  >(softDeleteCardMutation, {
    variables: {
      id: cardID,
    },
  })

  const handleSoftDeleteCard = async () => {
    try {
      let { errors } = await deleteCard()
      if (errors?.length) {
        Sentry.withScope((scope) => {
          scope.setLevel(Sentry.Severity.Error)
          scope.setExtra('errors', errors)
          Sentry.captureMessage(`deleteCard(id=${cardID}) failed`)
        })
        return
      }

      // TODO: Toast notification
      off()
      onDelete()
    } catch (err) {
      Sentry.captureException(err)
    }
  }

  if (isOpen) {
    return (
      <div className="flex flex-col text-xs">
        <label
          htmlFor="delete-card"
          className="font-medium leading-5 text-gray-700"
        >
          Type 'delete' to confirm deletion.
        </label>
        <input
          name="delete-card"
          type="text"
          value={deleteText}
          className="form-input block w-full sm:text-sm sm:leading-5"
          onChange={(e) => setDeleteText(e.currentTarget.value)}
        />
        <div className="flex items-center justify-between mt-3 space-x-2">
          <span className="rounded-md shadow-sm">
            <button
              disabled={isDisabled}
              className={`${
                isDisabled
                  ? 'opacity-50 cursor-not-allowed'
                  : 'hover:bg-red-500'
              } inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600  focus:outline-none focus:border-red-700 focus:ring-2 focus:ring-offset-2 focus:ring-red-500 active:bg-red-700`}
              type="button"
              onClick={handleSoftDeleteCard}
            >
              Delete
            </button>
          </span>
          <span className="rounded-md shadow-sm">
            <button
              className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 active:text-gray-800 active:bg-gray-50"
              type="button"
              onClick={off}
            >
              Cancel
            </button>
          </span>
        </div>
      </div>
    )
  }

  return (
    <button
      type="button"
      className="w-full inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-red-700 hover:bg-red-100 focus:outline-none focus:border-red-300 focus:ring-2 focus:ring-offset-2 focus:ring-red-500 active:bg-red-200"
      onClick={on}
    >
      <FontAwesomeIcon
        icon={['far', 'trash-alt']}
        className="-ml-0.5 mr-2 h-4 w-4"
      />
      Delete
    </button>
  )
}
