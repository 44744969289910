import React from 'react'
import cx from 'classnames'
import { useLocation } from 'react-router'
import { Avatar } from './Avatar'
import type { NavItem } from './Header'
import { NavLink } from './NavLink'

type Props = {
  isOpen: boolean
  user: {
    name: string
    email: string
  }
  headerNavItems: NavItem[]
  profileNavItems: NavItem[]
}

export function MobileMenu({
  isOpen,
  user,
  headerNavItems,
  profileNavItems,
}: Props) {
  const { pathname } = useLocation()
  return (
    <div className={`${isOpen ? 'block' : 'hidden'} md:hidden`}>
      <div className="px-2 pt-2 pb-3 sm:px-3">
        {headerNavItems.map(({ to, label }, idx) => {
          const first = cx({ 'mt-1': idx !== 0 })
          return (
            <NavLink
              key={to}
              to={to}
              label={label}
              active={pathname === to}
              className={`block text-base ${first}`}
            />
          )
        })}
      </div>
      <div className="pt-4 pb-3 border-t border-gray-700">
        <div className="flex items-center px-5 sm:px-6">
          <div className="flex-shrink-0">
            <Avatar email={user.email} tailwindSize={10} />
          </div>
          <div className="ml-3">
            <div className="text-base font-medium leading-6 text-white">
              {user.name}
            </div>
            <div className="text-sm font-medium leading-5 text-gray-400">
              {user.email}
            </div>
          </div>
        </div>
        <div className="mt-3 px-2 sm:px-3">
          {profileNavItems.map(({ to, label }, idx) => {
            const first = cx({ 'mt-1': idx !== 0 })
            return (
              <NavLink
                key={to}
                to={to}
                label={label}
                active={pathname === to}
                className={`block ${first}`}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
