/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteClockpunchMutation
// ====================================================

export interface DeleteClockpunchMutation_delete_clockpunches_by_pk {
  __typename: "clockpunches";
  id: number;
}

export interface DeleteClockpunchMutation {
  /**
   * delete single row from the table: "clockpunches"
   */
  delete_clockpunches_by_pk: DeleteClockpunchMutation_delete_clockpunches_by_pk | null;
}

export interface DeleteClockpunchMutationVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InsertCardMutation
// ====================================================

export interface InsertCardMutation_insert_cards_one_author {
  __typename: "users";
  id: string;
  email: string;
  name: string;
}

export interface InsertCardMutation_insert_cards_one {
  __typename: "cards";
  id: number;
  column_id: number;
  order: Kanban.float8;
  title: string;
  description: string;
  /**
   * An object relationship
   */
  author: InsertCardMutation_insert_cards_one_author;
  created_at: Kanban.timestamptz;
  updated_at: Kanban.timestamptz | null;
  is_public: boolean;
  is_priority: boolean;
  state: card_state_enum;
}

export interface InsertCardMutation {
  /**
   * insert a single row into the table: "cards"
   */
  insert_cards_one: InsertCardMutation_insert_cards_one | null;
}

export interface InsertCardMutationVariables {
  input: cards_insert_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InsertCardActivityMutation
// ====================================================

export interface InsertCardActivityMutation_insert_card_activity_one_actor {
  __typename: "users";
  id: string;
  name: string;
  email: string;
}

export interface InsertCardActivityMutation_insert_card_activity_one {
  __typename: "card_activity";
  id: number;
  created_at: Kanban.timestamptz;
  action: string;
  /**
   * An object relationship
   */
  actor: InsertCardActivityMutation_insert_card_activity_one_actor;
}

export interface InsertCardActivityMutation {
  /**
   * insert a single row into the table: "card_activity"
   */
  insert_card_activity_one: InsertCardActivityMutation_insert_card_activity_one | null;
}

export interface InsertCardActivityMutationVariables {
  input: card_activity_insert_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InsertCardAttachmentMutation
// ====================================================

export interface InsertCardAttachmentMutation_insert_card_attachment_one_author {
  __typename: "users";
  id: string;
  name: string;
  email: string;
}

export interface InsertCardAttachmentMutation_insert_card_attachment_one {
  __typename: "card_attachment";
  id: number;
  signed_id: string;
  size: number;
  type: string;
  name: string;
  created_at: Kanban.timestamptz;
  /**
   * An object relationship
   */
  author: InsertCardAttachmentMutation_insert_card_attachment_one_author;
}

export interface InsertCardAttachmentMutation {
  /**
   * insert a single row into the table: "card_attachment"
   */
  insert_card_attachment_one: InsertCardAttachmentMutation_insert_card_attachment_one | null;
}

export interface InsertCardAttachmentMutationVariables {
  input: card_attachment_insert_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InsertCardCommentMutation
// ====================================================

export interface InsertCardCommentMutation_insert_card_comment_one_author {
  __typename: "users";
  id: string;
  name: string;
  email: string;
}

export interface InsertCardCommentMutation_insert_card_comment_one {
  __typename: "card_comment";
  id: number;
  created_at: Kanban.timestamptz;
  message: string;
  /**
   * An object relationship
   */
  author: InsertCardCommentMutation_insert_card_comment_one_author;
}

export interface InsertCardCommentMutation {
  /**
   * insert a single row into the table: "card_comment"
   */
  insert_card_comment_one: InsertCardCommentMutation_insert_card_comment_one | null;
}

export interface InsertCardCommentMutationVariables {
  input: card_comment_insert_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InsertCardTimeMutation
// ====================================================

export interface InsertCardTimeMutation_insert_card_time_one_user {
  __typename: "users";
  name: string;
  email: string;
}

export interface InsertCardTimeMutation_insert_card_time_one {
  __typename: "card_time";
  id: number;
  diff: number | null;
  start: Kanban.timestamp;
  stop: Kanban.timestamp;
  /**
   * An object relationship
   */
  user: InsertCardTimeMutation_insert_card_time_one_user;
}

export interface InsertCardTimeMutation {
  /**
   * insert a single row into the table: "card_time"
   */
  insert_card_time_one: InsertCardTimeMutation_insert_card_time_one | null;
}

export interface InsertCardTimeMutationVariables {
  input: card_time_insert_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InsertClockpunchMutation
// ====================================================

export interface InsertClockpunchMutation_insert_clockpunches_one {
  __typename: "clockpunches";
  id: number;
  punch_in: Kanban.timestamptz;
  punch_out: Kanban.timestamptz | null;
}

export interface InsertClockpunchMutation {
  /**
   * insert a single row into the table: "clockpunches"
   */
  insert_clockpunches_one: InsertClockpunchMutation_insert_clockpunches_one | null;
}

export interface InsertClockpunchMutationVariables {
  userId: string;
  punchIn: Kanban.timestamptz;
  punchOut?: Kanban.timestamptz | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SoftDeleteCardMutation
// ====================================================

export interface SoftDeleteCardMutation_update_cards_by_pk {
  __typename: "cards";
  id: number;
  deleted_at: Kanban.timestamptz | null;
}

export interface SoftDeleteCardMutation {
  /**
   * update single row of the table: "cards"
   */
  update_cards_by_pk: SoftDeleteCardMutation_update_cards_by_pk | null;
}

export interface SoftDeleteCardMutationVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCardMutation
// ====================================================

export interface UpdateCardMutation_update_cards_by_pk_author {
  __typename: "users";
  id: string;
  email: string;
  name: string;
}

export interface UpdateCardMutation_update_cards_by_pk {
  __typename: "cards";
  id: number;
  column_id: number;
  order: Kanban.float8;
  title: string;
  description: string;
  /**
   * An object relationship
   */
  author: UpdateCardMutation_update_cards_by_pk_author;
  created_at: Kanban.timestamptz;
  updated_at: Kanban.timestamptz | null;
  is_public: boolean;
  is_priority: boolean;
  state: card_state_enum;
}

export interface UpdateCardMutation {
  /**
   * update single row of the table: "cards"
   */
  update_cards_by_pk: UpdateCardMutation_update_cards_by_pk | null;
}

export interface UpdateCardMutationVariables {
  input: cards_set_input;
  ids: cards_pk_columns_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCardModalMutation
// ====================================================

export interface UpdateCardModalMutation_update_cards_by_pk_author {
  __typename: "users";
  id: string;
  email: string;
  name: string;
}

export interface UpdateCardModalMutation_update_cards_by_pk_card_times_user {
  __typename: "users";
  name: string;
  email: string;
}

export interface UpdateCardModalMutation_update_cards_by_pk_card_times {
  __typename: "card_time";
  id: number;
  diff: number | null;
  start: Kanban.timestamp;
  stop: Kanban.timestamp;
  /**
   * An object relationship
   */
  user: UpdateCardModalMutation_update_cards_by_pk_card_times_user;
}

export interface UpdateCardModalMutation_update_cards_by_pk_card_attachments_author {
  __typename: "users";
  id: string;
  name: string;
  email: string;
}

export interface UpdateCardModalMutation_update_cards_by_pk_card_attachments {
  __typename: "card_attachment";
  id: number;
  signed_id: string;
  size: number;
  type: string;
  name: string;
  created_at: Kanban.timestamptz;
  /**
   * An object relationship
   */
  author: UpdateCardModalMutation_update_cards_by_pk_card_attachments_author;
}

export interface UpdateCardModalMutation_update_cards_by_pk_card_comments_author {
  __typename: "users";
  id: string;
  name: string;
  email: string;
}

export interface UpdateCardModalMutation_update_cards_by_pk_card_comments {
  __typename: "card_comment";
  id: number;
  created_at: Kanban.timestamptz;
  message: string;
  /**
   * An object relationship
   */
  author: UpdateCardModalMutation_update_cards_by_pk_card_comments_author;
}

export interface UpdateCardModalMutation_update_cards_by_pk_card_activity_actor {
  __typename: "users";
  id: string;
  name: string;
  email: string;
}

export interface UpdateCardModalMutation_update_cards_by_pk_card_activity {
  __typename: "card_activity";
  id: number;
  created_at: Kanban.timestamptz;
  action: string;
  /**
   * An object relationship
   */
  actor: UpdateCardModalMutation_update_cards_by_pk_card_activity_actor;
}

export interface UpdateCardModalMutation_update_cards_by_pk {
  __typename: "cards";
  id: number;
  title: string;
  description: string;
  /**
   * An object relationship
   */
  author: UpdateCardModalMutation_update_cards_by_pk_author;
  created_at: Kanban.timestamptz;
  updated_at: Kanban.timestamptz | null;
  is_public: boolean;
  is_priority: boolean;
  state: card_state_enum;
  /**
   * An array relationship
   */
  card_times: UpdateCardModalMutation_update_cards_by_pk_card_times[];
  /**
   * An array relationship
   */
  card_attachments: UpdateCardModalMutation_update_cards_by_pk_card_attachments[];
  /**
   * An array relationship
   */
  card_comments: UpdateCardModalMutation_update_cards_by_pk_card_comments[];
  /**
   * An array relationship
   */
  card_activity: UpdateCardModalMutation_update_cards_by_pk_card_activity[];
}

export interface UpdateCardModalMutation {
  /**
   * update single row of the table: "cards"
   */
  update_cards_by_pk: UpdateCardModalMutation_update_cards_by_pk | null;
}

export interface UpdateCardModalMutationVariables {
  input: cards_set_input;
  ids: cards_pk_columns_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCardPriorityMutation
// ====================================================

export interface UpdateCardPriorityMutation_update_cards_by_pk {
  __typename: "cards";
  id: number;
  is_priority: boolean;
}

export interface UpdateCardPriorityMutation {
  /**
   * update single row of the table: "cards"
   */
  update_cards_by_pk: UpdateCardPriorityMutation_update_cards_by_pk | null;
}

export interface UpdateCardPriorityMutationVariables {
  input: cards_set_input;
  ids: cards_pk_columns_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCardStateMutation
// ====================================================

export interface UpdateCardStateMutation_update_cards_by_pk {
  __typename: "cards";
  id: number;
  state: card_state_enum;
}

export interface UpdateCardStateMutation {
  /**
   * update single row of the table: "cards"
   */
  update_cards_by_pk: UpdateCardStateMutation_update_cards_by_pk | null;
}

export interface UpdateCardStateMutationVariables {
  input: cards_set_input;
  ids: cards_pk_columns_input;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCardTimeMutation
// ====================================================

export interface UpdateCardTimeMutation_update_card_time_by_pk {
  __typename: "card_time";
  id: number;
  stop: Kanban.timestamp;
  diff: number | null;
}

export interface UpdateCardTimeMutation {
  /**
   * update single row of the table: "card_time"
   */
  update_card_time_by_pk: UpdateCardTimeMutation_update_card_time_by_pk | null;
}

export interface UpdateCardTimeMutationVariables {
  id: number;
  stop: Kanban.timestamp;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateClockPunchMutation
// ====================================================

export interface UpdateClockPunchMutation_update_clockpunches_by_pk {
  __typename: "clockpunches";
  id: number;
  punch_in: Kanban.timestamptz;
  punch_out: Kanban.timestamptz | null;
}

export interface UpdateClockPunchMutation {
  /**
   * update single row of the table: "clockpunches"
   */
  update_clockpunches_by_pk: UpdateClockPunchMutation_update_clockpunches_by_pk | null;
}

export interface UpdateClockPunchMutationVariables {
  id: number;
  punchIn: Kanban.timestamptz;
  punchOut: Kanban.timestamptz;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUserMutation
// ====================================================

export interface UpdateUserMutation_update_users_by_pk {
  __typename: "users";
  id: string;
  name: string;
  email: string;
  title: string | null;
  phone: string | null;
  dbid: number;
}

export interface UpdateUserMutation {
  /**
   * update single row of the table: "users"
   */
  update_users_by_pk: UpdateUserMutation_update_users_by_pk | null;
}

export interface UpdateUserMutationVariables {
  id: number;
  name: string;
  title: string;
  phone: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadRequestMutation
// ====================================================

export interface UploadRequestMutation_uploadRequest {
  __typename: "UploadRequestResponse";
  id: string;
  presigned_url: string;
}

export interface UploadRequestMutation {
  /**
   * perform the action: "uploadRequest"
   */
  uploadRequest: UploadRequestMutation_uploadRequest | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CardModalQuery
// ====================================================

export interface CardModalQuery_cards_by_pk_author {
  __typename: "users";
  id: string;
  email: string;
  name: string;
}

export interface CardModalQuery_cards_by_pk_card_times_user {
  __typename: "users";
  name: string;
  email: string;
}

export interface CardModalQuery_cards_by_pk_card_times {
  __typename: "card_time";
  id: number;
  diff: number | null;
  start: Kanban.timestamp;
  stop: Kanban.timestamp;
  /**
   * An object relationship
   */
  user: CardModalQuery_cards_by_pk_card_times_user;
}

export interface CardModalQuery_cards_by_pk_card_attachments_author {
  __typename: "users";
  id: string;
  name: string;
  email: string;
}

export interface CardModalQuery_cards_by_pk_card_attachments {
  __typename: "card_attachment";
  id: number;
  signed_id: string;
  size: number;
  type: string;
  name: string;
  created_at: Kanban.timestamptz;
  /**
   * An object relationship
   */
  author: CardModalQuery_cards_by_pk_card_attachments_author;
}

export interface CardModalQuery_cards_by_pk_card_comments_author {
  __typename: "users";
  id: string;
  name: string;
  email: string;
}

export interface CardModalQuery_cards_by_pk_card_comments {
  __typename: "card_comment";
  id: number;
  created_at: Kanban.timestamptz;
  message: string;
  /**
   * An object relationship
   */
  author: CardModalQuery_cards_by_pk_card_comments_author;
}

export interface CardModalQuery_cards_by_pk_card_activity_actor {
  __typename: "users";
  id: string;
  name: string;
  email: string;
}

export interface CardModalQuery_cards_by_pk_card_activity {
  __typename: "card_activity";
  id: number;
  created_at: Kanban.timestamptz;
  action: string;
  /**
   * An object relationship
   */
  actor: CardModalQuery_cards_by_pk_card_activity_actor;
}

export interface CardModalQuery_cards_by_pk {
  __typename: "cards";
  id: number;
  title: string;
  description: string;
  /**
   * An object relationship
   */
  author: CardModalQuery_cards_by_pk_author;
  created_at: Kanban.timestamptz;
  updated_at: Kanban.timestamptz | null;
  is_public: boolean;
  is_priority: boolean;
  state: card_state_enum;
  /**
   * An array relationship
   */
  card_times: CardModalQuery_cards_by_pk_card_times[];
  /**
   * An array relationship
   */
  card_attachments: CardModalQuery_cards_by_pk_card_attachments[];
  /**
   * An array relationship
   */
  card_comments: CardModalQuery_cards_by_pk_card_comments[];
  /**
   * An array relationship
   */
  card_activity: CardModalQuery_cards_by_pk_card_activity[];
}

export interface CardModalQuery {
  /**
   * fetch data from the table: "cards" using primary key columns
   */
  cards_by_pk: CardModalQuery_cards_by_pk | null;
}

export interface CardModalQueryVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ColumnsQuery
// ====================================================

export interface ColumnsQuery_columns_owner {
  __typename: "users";
  id: string;
  name: string;
  email: string;
}

export interface ColumnsQuery_columns_cards_author {
  __typename: "users";
  id: string;
  email: string;
  name: string;
}

export interface ColumnsQuery_columns_cards {
  __typename: "cards";
  id: number;
  column_id: number;
  order: Kanban.float8;
  title: string;
  description: string;
  /**
   * An object relationship
   */
  author: ColumnsQuery_columns_cards_author;
  created_at: Kanban.timestamptz;
  updated_at: Kanban.timestamptz | null;
  is_public: boolean;
  is_priority: boolean;
  state: card_state_enum;
}

export interface ColumnsQuery_columns {
  __typename: "columns";
  id: number;
  title: string;
  /**
   * An object relationship
   */
  owner: ColumnsQuery_columns_owner;
  /**
   * An array relationship
   */
  cards: ColumnsQuery_columns_cards[];
}

export interface ColumnsQuery {
  /**
   * fetch data from the table: "columns"
   */
  columns: ColumnsQuery_columns[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LatestCardTimeQuery
// ====================================================

export interface LatestCardTimeQuery_card_time {
  __typename: "card_time";
  id: number;
  start: Kanban.timestamp;
  stop: Kanban.timestamp;
}

export interface LatestCardTimeQuery {
  /**
   * fetch data from the table: "card_time"
   */
  card_time: LatestCardTimeQuery_card_time[];
}

export interface LatestCardTimeQueryVariables {
  cardId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MeQuery
// ====================================================

export interface MeQuery_users {
  __typename: "users";
  id: string;
  name: string;
  email: string;
  title: string | null;
  phone: string | null;
  dbid: number;
}

export interface MeQuery {
  /**
   * fetch data from the table: "users"
   */
  users: MeQuery_users[];
}

export interface MeQueryVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TeamQuery
// ====================================================

export interface TeamQuery_users {
  __typename: "users";
  id: string;
  dbid: number;
  name: string;
  email: string;
  title: string | null;
  phone: string | null;
}

export interface TeamQuery {
  /**
   * fetch data from the table: "users"
   */
  users: TeamQuery_users[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TeamClockpunchesQuery
// ====================================================

export interface TeamClockpunchesQuery_users_clockpunches {
  __typename: "clockpunches";
  id: number;
  punch_in: Kanban.timestamptz;
  punch_out: Kanban.timestamptz | null;
}

export interface TeamClockpunchesQuery_users {
  __typename: "users";
  id: string;
  dbid: number;
  name: string;
  email: string;
  title: string | null;
  phone: string | null;
  /**
   * An array relationship
   */
  clockpunches: TeamClockpunchesQuery_users_clockpunches[];
}

export interface TeamClockpunchesQuery {
  /**
   * fetch data from the table: "users"
   */
  users: TeamClockpunchesQuery_users[];
}

export interface TeamClockpunchesQueryVariables {
  startOfWeek: Kanban.timestamptz;
  endOfWeek: Kanban.timestamptz;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserClockpunchesQuery
// ====================================================

export interface UserClockpunchesQuery_users_by_pk_clockpunches {
  __typename: "clockpunches";
  id: number;
  punch_in: Kanban.timestamptz;
  punch_out: Kanban.timestamptz | null;
}

export interface UserClockpunchesQuery_users_by_pk {
  __typename: "users";
  id: string;
  dbid: number;
  name: string;
  email: string;
  title: string | null;
  phone: string | null;
  /**
   * An array relationship
   */
  clockpunches: UserClockpunchesQuery_users_by_pk_clockpunches[];
}

export interface UserClockpunchesQuery {
  /**
   * fetch data from the table: "users" using primary key columns
   */
  users_by_pk: UserClockpunchesQuery_users_by_pk | null;
}

export interface UserClockpunchesQueryVariables {
  userId: number;
  startOfWeek: Kanban.timestamptz;
  endOfWeek: Kanban.timestamptz;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: CardModalSubscription
// ====================================================

export interface CardModalSubscription_cards_by_pk_author {
  __typename: "users";
  id: string;
  email: string;
  name: string;
}

export interface CardModalSubscription_cards_by_pk_card_times_user {
  __typename: "users";
  name: string;
  email: string;
}

export interface CardModalSubscription_cards_by_pk_card_times {
  __typename: "card_time";
  id: number;
  diff: number | null;
  start: Kanban.timestamp;
  stop: Kanban.timestamp;
  /**
   * An object relationship
   */
  user: CardModalSubscription_cards_by_pk_card_times_user;
}

export interface CardModalSubscription_cards_by_pk_card_attachments_author {
  __typename: "users";
  id: string;
  name: string;
  email: string;
}

export interface CardModalSubscription_cards_by_pk_card_attachments {
  __typename: "card_attachment";
  id: number;
  signed_id: string;
  size: number;
  type: string;
  name: string;
  created_at: Kanban.timestamptz;
  /**
   * An object relationship
   */
  author: CardModalSubscription_cards_by_pk_card_attachments_author;
}

export interface CardModalSubscription_cards_by_pk_card_comments_author {
  __typename: "users";
  id: string;
  name: string;
  email: string;
}

export interface CardModalSubscription_cards_by_pk_card_comments {
  __typename: "card_comment";
  id: number;
  created_at: Kanban.timestamptz;
  message: string;
  /**
   * An object relationship
   */
  author: CardModalSubscription_cards_by_pk_card_comments_author;
}

export interface CardModalSubscription_cards_by_pk_card_activity_actor {
  __typename: "users";
  id: string;
  name: string;
  email: string;
}

export interface CardModalSubscription_cards_by_pk_card_activity {
  __typename: "card_activity";
  id: number;
  created_at: Kanban.timestamptz;
  action: string;
  /**
   * An object relationship
   */
  actor: CardModalSubscription_cards_by_pk_card_activity_actor;
}

export interface CardModalSubscription_cards_by_pk {
  __typename: "cards";
  id: number;
  title: string;
  description: string;
  /**
   * An object relationship
   */
  author: CardModalSubscription_cards_by_pk_author;
  created_at: Kanban.timestamptz;
  updated_at: Kanban.timestamptz | null;
  is_public: boolean;
  is_priority: boolean;
  state: card_state_enum;
  /**
   * An array relationship
   */
  card_times: CardModalSubscription_cards_by_pk_card_times[];
  /**
   * An array relationship
   */
  card_attachments: CardModalSubscription_cards_by_pk_card_attachments[];
  /**
   * An array relationship
   */
  card_comments: CardModalSubscription_cards_by_pk_card_comments[];
  /**
   * An array relationship
   */
  card_activity: CardModalSubscription_cards_by_pk_card_activity[];
}

export interface CardModalSubscription {
  /**
   * fetch data from the table: "cards" using primary key columns
   */
  cards_by_pk: CardModalSubscription_cards_by_pk | null;
}

export interface CardModalSubscriptionVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: ColumnsSubscription
// ====================================================

export interface ColumnsSubscription_columns_owner {
  __typename: "users";
  id: string;
  name: string;
  email: string;
}

export interface ColumnsSubscription_columns_cards_author {
  __typename: "users";
  id: string;
  email: string;
  name: string;
}

export interface ColumnsSubscription_columns_cards {
  __typename: "cards";
  id: number;
  column_id: number;
  order: Kanban.float8;
  title: string;
  description: string;
  /**
   * An object relationship
   */
  author: ColumnsSubscription_columns_cards_author;
  created_at: Kanban.timestamptz;
  updated_at: Kanban.timestamptz | null;
  is_public: boolean;
  is_priority: boolean;
  state: card_state_enum;
}

export interface ColumnsSubscription_columns {
  __typename: "columns";
  id: number;
  title: string;
  /**
   * An object relationship
   */
  owner: ColumnsSubscription_columns_owner;
  /**
   * An array relationship
   */
  cards: ColumnsSubscription_columns_cards[];
}

export interface ColumnsSubscription {
  /**
   * fetch data from the table: "columns"
   */
  columns: ColumnsSubscription_columns[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CardFragment
// ====================================================

export interface CardFragment_author {
  __typename: "users";
  id: string;
  email: string;
  name: string;
}

export interface CardFragment {
  __typename: "cards";
  id: number;
  column_id: number;
  order: Kanban.float8;
  title: string;
  description: string;
  /**
   * An object relationship
   */
  author: CardFragment_author;
  created_at: Kanban.timestamptz;
  updated_at: Kanban.timestamptz | null;
  is_public: boolean;
  is_priority: boolean;
  state: card_state_enum;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CardActivityFragment
// ====================================================

export interface CardActivityFragment_actor {
  __typename: "users";
  id: string;
  name: string;
  email: string;
}

export interface CardActivityFragment {
  __typename: "card_activity";
  id: number;
  created_at: Kanban.timestamptz;
  action: string;
  /**
   * An object relationship
   */
  actor: CardActivityFragment_actor;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CardAttachmentFragment
// ====================================================

export interface CardAttachmentFragment_author {
  __typename: "users";
  id: string;
  name: string;
  email: string;
}

export interface CardAttachmentFragment {
  __typename: "card_attachment";
  id: number;
  signed_id: string;
  size: number;
  type: string;
  name: string;
  created_at: Kanban.timestamptz;
  /**
   * An object relationship
   */
  author: CardAttachmentFragment_author;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CardCommentFragment
// ====================================================

export interface CardCommentFragment_author {
  __typename: "users";
  id: string;
  name: string;
  email: string;
}

export interface CardCommentFragment {
  __typename: "card_comment";
  id: number;
  created_at: Kanban.timestamptz;
  message: string;
  /**
   * An object relationship
   */
  author: CardCommentFragment_author;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CardModalFragment
// ====================================================

export interface CardModalFragment_author {
  __typename: "users";
  id: string;
  email: string;
  name: string;
}

export interface CardModalFragment_card_times_user {
  __typename: "users";
  name: string;
  email: string;
}

export interface CardModalFragment_card_times {
  __typename: "card_time";
  id: number;
  diff: number | null;
  start: Kanban.timestamp;
  stop: Kanban.timestamp;
  /**
   * An object relationship
   */
  user: CardModalFragment_card_times_user;
}

export interface CardModalFragment_card_attachments_author {
  __typename: "users";
  id: string;
  name: string;
  email: string;
}

export interface CardModalFragment_card_attachments {
  __typename: "card_attachment";
  id: number;
  signed_id: string;
  size: number;
  type: string;
  name: string;
  created_at: Kanban.timestamptz;
  /**
   * An object relationship
   */
  author: CardModalFragment_card_attachments_author;
}

export interface CardModalFragment_card_comments_author {
  __typename: "users";
  id: string;
  name: string;
  email: string;
}

export interface CardModalFragment_card_comments {
  __typename: "card_comment";
  id: number;
  created_at: Kanban.timestamptz;
  message: string;
  /**
   * An object relationship
   */
  author: CardModalFragment_card_comments_author;
}

export interface CardModalFragment_card_activity_actor {
  __typename: "users";
  id: string;
  name: string;
  email: string;
}

export interface CardModalFragment_card_activity {
  __typename: "card_activity";
  id: number;
  created_at: Kanban.timestamptz;
  action: string;
  /**
   * An object relationship
   */
  actor: CardModalFragment_card_activity_actor;
}

export interface CardModalFragment {
  __typename: "cards";
  id: number;
  title: string;
  description: string;
  /**
   * An object relationship
   */
  author: CardModalFragment_author;
  created_at: Kanban.timestamptz;
  updated_at: Kanban.timestamptz | null;
  is_public: boolean;
  is_priority: boolean;
  state: card_state_enum;
  /**
   * An array relationship
   */
  card_times: CardModalFragment_card_times[];
  /**
   * An array relationship
   */
  card_attachments: CardModalFragment_card_attachments[];
  /**
   * An array relationship
   */
  card_comments: CardModalFragment_card_comments[];
  /**
   * An array relationship
   */
  card_activity: CardModalFragment_card_activity[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CardPriorityFragment
// ====================================================

export interface CardPriorityFragment {
  __typename: "cards";
  id: number;
  is_priority: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CardStateFragment
// ====================================================

export interface CardStateFragment {
  __typename: "cards";
  id: number;
  state: card_state_enum;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CardTimeFragment
// ====================================================

export interface CardTimeFragment_user {
  __typename: "users";
  name: string;
  email: string;
}

export interface CardTimeFragment {
  __typename: "card_time";
  id: number;
  diff: number | null;
  start: Kanban.timestamp;
  stop: Kanban.timestamp;
  /**
   * An object relationship
   */
  user: CardTimeFragment_user;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CardTimeUpdateFragment
// ====================================================

export interface CardTimeUpdateFragment {
  __typename: "card_time";
  id: number;
  stop: Kanban.timestamp;
  diff: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClockpunchFragment
// ====================================================

export interface ClockpunchFragment {
  __typename: "clockpunches";
  id: number;
  punch_in: Kanban.timestamptz;
  punch_out: Kanban.timestamptz | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ColumnFragment
// ====================================================

export interface ColumnFragment {
  __typename: "columns";
  id: number;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MeFragment
// ====================================================

export interface MeFragment {
  __typename: "users";
  id: string;
  name: string;
  email: string;
  title: string | null;
  phone: string | null;
  dbid: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TeamFragment
// ====================================================

export interface TeamFragment {
  __typename: "users";
  id: string;
  dbid: number;
  name: string;
  email: string;
  title: string | null;
  phone: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserFragment
// ====================================================

export interface UserFragment {
  __typename: "users";
  id: string;
  name: string;
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserClockpunchesFragment
// ====================================================

export interface UserClockpunchesFragment {
  __typename: "users";
  id: string;
  name: string;
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * unique or primary key constraints on table "card_activity"
 */
export enum card_activity_constraint {
  card_activity_pkey = "card_activity_pkey",
}

/**
 * update columns of table "card_activity"
 */
export enum card_activity_update_column {
  action = "action",
  actor_id = "actor_id",
  card_id = "card_id",
  created_at = "created_at",
  id = "id",
}

/**
 * unique or primary key constraints on table "card_attachment"
 */
export enum card_attachment_constraint {
  card_attachment_pkey = "card_attachment_pkey",
  card_attachment_signed_id_key = "card_attachment_signed_id_key",
}

/**
 * update columns of table "card_attachment"
 */
export enum card_attachment_update_column {
  author_id = "author_id",
  card_id = "card_id",
  created_at = "created_at",
  id = "id",
  name = "name",
  signed_id = "signed_id",
  size = "size",
  type = "type",
}

/**
 * unique or primary key constraints on table "card_comment"
 */
export enum card_comment_constraint {
  card_comment_pkey = "card_comment_pkey",
}

/**
 * update columns of table "card_comment"
 */
export enum card_comment_update_column {
  author_id = "author_id",
  card_id = "card_id",
  created_at = "created_at",
  id = "id",
  message = "message",
}

export enum card_state_enum {
  BLUE = "BLUE",
  GREEN = "GREEN",
  RED = "RED",
  YELLOW = "YELLOW",
}

/**
 * unique or primary key constraints on table "card_time"
 */
export enum card_time_constraint {
  card_time_pkey = "card_time_pkey",
}

/**
 * update columns of table "card_time"
 */
export enum card_time_update_column {
  card_id = "card_id",
  diff = "diff",
  id = "id",
  start = "start",
  stop = "stop",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "cards"
 */
export enum cards_constraint {
  card_pkey = "card_pkey",
  cards_order_column_id_key = "cards_order_column_id_key",
}

/**
 * update columns of table "cards"
 */
export enum cards_update_column {
  author_id = "author_id",
  column_id = "column_id",
  created_at = "created_at",
  deleted_at = "deleted_at",
  description = "description",
  id = "id",
  is_priority = "is_priority",
  is_public = "is_public",
  order = "order",
  state = "state",
  title = "title",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "clockpunches"
 */
export enum clockpunches_constraint {
  clockpunches_pkey = "clockpunches_pkey",
}

/**
 * update columns of table "clockpunches"
 */
export enum clockpunches_update_column {
  id = "id",
  punch_in = "punch_in",
  punch_out = "punch_out",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "columns"
 */
export enum columns_constraint {
  column_pkey = "column_pkey",
  columns_owner_id_key = "columns_owner_id_key",
}

/**
 * update columns of table "columns"
 */
export enum columns_update_column {
  created_at = "created_at",
  id = "id",
  owner_id = "owner_id",
  title = "title",
  updated_at = "updated_at",
}

/**
 * unique or primary key constraints on table "users"
 */
export enum users_constraint {
  user_email_key = "user_email_key",
  user_pkey = "user_pkey",
  users_auth0_id_key = "users_auth0_id_key",
}

/**
 * update columns of table "users"
 */
export enum users_update_column {
  created_at = "created_at",
  dbid = "dbid",
  email = "email",
  id = "id",
  last_seen = "last_seen",
  name = "name",
  phone = "phone",
  pin = "pin",
  title = "title",
  updated_at = "updated_at",
}

/**
 * expression to compare columns of type Boolean. All fields are combined with logical 'AND'.
 */
export interface Boolean_comparison_exp {
  _eq?: boolean | null;
  _gt?: boolean | null;
  _gte?: boolean | null;
  _in?: boolean[] | null;
  _is_null?: boolean | null;
  _lt?: boolean | null;
  _lte?: boolean | null;
  _neq?: boolean | null;
  _nin?: boolean[] | null;
}

/**
 * expression to compare columns of type Int. All fields are combined with logical 'AND'.
 */
export interface Int_comparison_exp {
  _eq?: number | null;
  _gt?: number | null;
  _gte?: number | null;
  _in?: number[] | null;
  _is_null?: boolean | null;
  _lt?: number | null;
  _lte?: number | null;
  _neq?: number | null;
  _nin?: number[] | null;
}

/**
 * expression to compare columns of type String. All fields are combined with logical 'AND'.
 */
export interface String_comparison_exp {
  _eq?: string | null;
  _gt?: string | null;
  _gte?: string | null;
  _ilike?: string | null;
  _in?: string[] | null;
  _is_null?: boolean | null;
  _like?: string | null;
  _lt?: string | null;
  _lte?: string | null;
  _neq?: string | null;
  _nilike?: string | null;
  _nin?: string[] | null;
  _nlike?: string | null;
  _nsimilar?: string | null;
  _similar?: string | null;
}

/**
 * input type for inserting array relation for remote table "card_activity"
 */
export interface card_activity_arr_rel_insert_input {
  data: card_activity_insert_input[];
  on_conflict?: card_activity_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "card_activity". All fields are combined with a logical 'AND'.
 */
export interface card_activity_bool_exp {
  _and?: (card_activity_bool_exp | null)[] | null;
  _not?: card_activity_bool_exp | null;
  _or?: (card_activity_bool_exp | null)[] | null;
  action?: String_comparison_exp | null;
  actor?: users_bool_exp | null;
  actor_id?: String_comparison_exp | null;
  card?: cards_bool_exp | null;
  card_id?: Int_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "card_activity"
 */
export interface card_activity_insert_input {
  action?: string | null;
  actor?: users_obj_rel_insert_input | null;
  actor_id?: string | null;
  card?: cards_obj_rel_insert_input | null;
  card_id?: number | null;
  created_at?: Kanban.timestamptz | null;
  id?: number | null;
}

/**
 * on conflict condition type for table "card_activity"
 */
export interface card_activity_on_conflict {
  constraint: card_activity_constraint;
  update_columns: card_activity_update_column[];
  where?: card_activity_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "card_attachment"
 */
export interface card_attachment_arr_rel_insert_input {
  data: card_attachment_insert_input[];
  on_conflict?: card_attachment_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "card_attachment". All fields are combined with a logical 'AND'.
 */
export interface card_attachment_bool_exp {
  _and?: (card_attachment_bool_exp | null)[] | null;
  _not?: card_attachment_bool_exp | null;
  _or?: (card_attachment_bool_exp | null)[] | null;
  author?: users_bool_exp | null;
  author_id?: String_comparison_exp | null;
  card?: cards_bool_exp | null;
  card_id?: Int_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  signed_id?: String_comparison_exp | null;
  size?: Int_comparison_exp | null;
  type?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "card_attachment"
 */
export interface card_attachment_insert_input {
  author?: users_obj_rel_insert_input | null;
  author_id?: string | null;
  card?: cards_obj_rel_insert_input | null;
  card_id?: number | null;
  created_at?: Kanban.timestamptz | null;
  id?: number | null;
  name?: string | null;
  signed_id?: string | null;
  size?: number | null;
  type?: string | null;
}

/**
 * on conflict condition type for table "card_attachment"
 */
export interface card_attachment_on_conflict {
  constraint: card_attachment_constraint;
  update_columns: card_attachment_update_column[];
  where?: card_attachment_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "card_comment"
 */
export interface card_comment_arr_rel_insert_input {
  data: card_comment_insert_input[];
  on_conflict?: card_comment_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "card_comment". All fields are combined with a logical 'AND'.
 */
export interface card_comment_bool_exp {
  _and?: (card_comment_bool_exp | null)[] | null;
  _not?: card_comment_bool_exp | null;
  _or?: (card_comment_bool_exp | null)[] | null;
  author?: users_bool_exp | null;
  author_id?: String_comparison_exp | null;
  card?: cards_bool_exp | null;
  card_id?: Int_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  message?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "card_comment"
 */
export interface card_comment_insert_input {
  author?: users_obj_rel_insert_input | null;
  author_id?: string | null;
  card?: cards_obj_rel_insert_input | null;
  card_id?: number | null;
  created_at?: Kanban.timestamptz | null;
  id?: number | null;
  message?: string | null;
}

/**
 * on conflict condition type for table "card_comment"
 */
export interface card_comment_on_conflict {
  constraint: card_comment_constraint;
  update_columns: card_comment_update_column[];
  where?: card_comment_bool_exp | null;
}

/**
 * expression to compare columns of type card_state_enum. All fields are combined with logical 'AND'.
 */
export interface card_state_enum_comparison_exp {
  _eq?: card_state_enum | null;
  _in?: card_state_enum[] | null;
  _is_null?: boolean | null;
  _neq?: card_state_enum | null;
  _nin?: card_state_enum[] | null;
}

/**
 * input type for inserting array relation for remote table "card_time"
 */
export interface card_time_arr_rel_insert_input {
  data: card_time_insert_input[];
  on_conflict?: card_time_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "card_time". All fields are combined with a logical 'AND'.
 */
export interface card_time_bool_exp {
  _and?: (card_time_bool_exp | null)[] | null;
  _not?: card_time_bool_exp | null;
  _or?: (card_time_bool_exp | null)[] | null;
  card_id?: Int_comparison_exp | null;
  diff?: Int_comparison_exp | null;
  id?: Int_comparison_exp | null;
  start?: timestamp_comparison_exp | null;
  stop?: timestamp_comparison_exp | null;
  user?: users_bool_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "card_time"
 */
export interface card_time_insert_input {
  card_id?: number | null;
  diff?: number | null;
  id?: number | null;
  start?: Kanban.timestamp | null;
  stop?: Kanban.timestamp | null;
  user?: users_obj_rel_insert_input | null;
  user_id?: string | null;
}

/**
 * on conflict condition type for table "card_time"
 */
export interface card_time_on_conflict {
  constraint: card_time_constraint;
  update_columns: card_time_update_column[];
  where?: card_time_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "cards"
 */
export interface cards_arr_rel_insert_input {
  data: cards_insert_input[];
  on_conflict?: cards_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "cards". All fields are combined with a logical 'AND'.
 */
export interface cards_bool_exp {
  _and?: (cards_bool_exp | null)[] | null;
  _not?: cards_bool_exp | null;
  _or?: (cards_bool_exp | null)[] | null;
  author?: users_bool_exp | null;
  author_id?: String_comparison_exp | null;
  card_activity?: card_activity_bool_exp | null;
  card_attachments?: card_attachment_bool_exp | null;
  card_comments?: card_comment_bool_exp | null;
  card_times?: card_time_bool_exp | null;
  column?: columns_bool_exp | null;
  column_id?: Int_comparison_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  deleted_at?: timestamptz_comparison_exp | null;
  description?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  is_priority?: Boolean_comparison_exp | null;
  is_public?: Boolean_comparison_exp | null;
  order?: float8_comparison_exp | null;
  state?: card_state_enum_comparison_exp | null;
  title?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "cards"
 */
export interface cards_insert_input {
  author?: users_obj_rel_insert_input | null;
  author_id?: string | null;
  card_activity?: card_activity_arr_rel_insert_input | null;
  card_attachments?: card_attachment_arr_rel_insert_input | null;
  card_comments?: card_comment_arr_rel_insert_input | null;
  card_times?: card_time_arr_rel_insert_input | null;
  column?: columns_obj_rel_insert_input | null;
  column_id?: number | null;
  created_at?: Kanban.timestamptz | null;
  deleted_at?: Kanban.timestamptz | null;
  description?: string | null;
  id?: number | null;
  is_priority?: boolean | null;
  is_public?: boolean | null;
  order?: Kanban.float8 | null;
  state?: card_state_enum | null;
  title?: string | null;
  updated_at?: Kanban.timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "cards"
 */
export interface cards_obj_rel_insert_input {
  data: cards_insert_input;
  on_conflict?: cards_on_conflict | null;
}

/**
 * on conflict condition type for table "cards"
 */
export interface cards_on_conflict {
  constraint: cards_constraint;
  update_columns: cards_update_column[];
  where?: cards_bool_exp | null;
}

/**
 * primary key columns input for table: "cards"
 */
export interface cards_pk_columns_input {
  id: number;
}

/**
 * input type for updating data in table "cards"
 */
export interface cards_set_input {
  author_id?: string | null;
  column_id?: number | null;
  created_at?: Kanban.timestamptz | null;
  deleted_at?: Kanban.timestamptz | null;
  description?: string | null;
  id?: number | null;
  is_priority?: boolean | null;
  is_public?: boolean | null;
  order?: Kanban.float8 | null;
  state?: card_state_enum | null;
  title?: string | null;
  updated_at?: Kanban.timestamptz | null;
}

/**
 * input type for inserting array relation for remote table "clockpunches"
 */
export interface clockpunches_arr_rel_insert_input {
  data: clockpunches_insert_input[];
  on_conflict?: clockpunches_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "clockpunches". All fields are combined with a logical 'AND'.
 */
export interface clockpunches_bool_exp {
  _and?: (clockpunches_bool_exp | null)[] | null;
  _not?: clockpunches_bool_exp | null;
  _or?: (clockpunches_bool_exp | null)[] | null;
  id?: Int_comparison_exp | null;
  punch_in?: timestamptz_comparison_exp | null;
  punch_out?: timestamptz_comparison_exp | null;
  user?: users_bool_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "clockpunches"
 */
export interface clockpunches_insert_input {
  id?: number | null;
  punch_in?: Kanban.timestamptz | null;
  punch_out?: Kanban.timestamptz | null;
  user?: users_obj_rel_insert_input | null;
  user_id?: string | null;
}

/**
 * on conflict condition type for table "clockpunches"
 */
export interface clockpunches_on_conflict {
  constraint: clockpunches_constraint;
  update_columns: clockpunches_update_column[];
  where?: clockpunches_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "columns". All fields are combined with a logical 'AND'.
 */
export interface columns_bool_exp {
  _and?: (columns_bool_exp | null)[] | null;
  _not?: columns_bool_exp | null;
  _or?: (columns_bool_exp | null)[] | null;
  cards?: cards_bool_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  id?: Int_comparison_exp | null;
  owner?: users_bool_exp | null;
  owner_id?: String_comparison_exp | null;
  title?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "columns"
 */
export interface columns_insert_input {
  cards?: cards_arr_rel_insert_input | null;
  created_at?: Kanban.timestamptz | null;
  id?: number | null;
  owner?: users_obj_rel_insert_input | null;
  owner_id?: string | null;
  title?: string | null;
  updated_at?: Kanban.timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "columns"
 */
export interface columns_obj_rel_insert_input {
  data: columns_insert_input;
  on_conflict?: columns_on_conflict | null;
}

/**
 * on conflict condition type for table "columns"
 */
export interface columns_on_conflict {
  constraint: columns_constraint;
  update_columns: columns_update_column[];
  where?: columns_bool_exp | null;
}

/**
 * expression to compare columns of type float8. All fields are combined with logical 'AND'.
 */
export interface float8_comparison_exp {
  _eq?: Kanban.float8 | null;
  _gt?: Kanban.float8 | null;
  _gte?: Kanban.float8 | null;
  _in?: Kanban.float8[] | null;
  _is_null?: boolean | null;
  _lt?: Kanban.float8 | null;
  _lte?: Kanban.float8 | null;
  _neq?: Kanban.float8 | null;
  _nin?: Kanban.float8[] | null;
}

/**
 * expression to compare columns of type timestamp. All fields are combined with logical 'AND'.
 */
export interface timestamp_comparison_exp {
  _eq?: Kanban.timestamp | null;
  _gt?: Kanban.timestamp | null;
  _gte?: Kanban.timestamp | null;
  _in?: Kanban.timestamp[] | null;
  _is_null?: boolean | null;
  _lt?: Kanban.timestamp | null;
  _lte?: Kanban.timestamp | null;
  _neq?: Kanban.timestamp | null;
  _nin?: Kanban.timestamp[] | null;
}

/**
 * expression to compare columns of type timestamptz. All fields are combined with logical 'AND'.
 */
export interface timestamptz_comparison_exp {
  _eq?: Kanban.timestamptz | null;
  _gt?: Kanban.timestamptz | null;
  _gte?: Kanban.timestamptz | null;
  _in?: Kanban.timestamptz[] | null;
  _is_null?: boolean | null;
  _lt?: Kanban.timestamptz | null;
  _lte?: Kanban.timestamptz | null;
  _neq?: Kanban.timestamptz | null;
  _nin?: Kanban.timestamptz[] | null;
}

/**
 * Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'.
 */
export interface users_bool_exp {
  _and?: (users_bool_exp | null)[] | null;
  _not?: users_bool_exp | null;
  _or?: (users_bool_exp | null)[] | null;
  clockpunches?: clockpunches_bool_exp | null;
  created_at?: timestamptz_comparison_exp | null;
  dbid?: Int_comparison_exp | null;
  email?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  last_seen?: timestamptz_comparison_exp | null;
  name?: String_comparison_exp | null;
  phone?: String_comparison_exp | null;
  pin?: String_comparison_exp | null;
  title?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting data into table "users"
 */
export interface users_insert_input {
  clockpunches?: clockpunches_arr_rel_insert_input | null;
  created_at?: Kanban.timestamptz | null;
  dbid?: number | null;
  email?: string | null;
  id?: string | null;
  last_seen?: Kanban.timestamptz | null;
  name?: string | null;
  phone?: string | null;
  pin?: string | null;
  title?: string | null;
  updated_at?: Kanban.timestamptz | null;
}

/**
 * input type for inserting object relation for remote table "users"
 */
export interface users_obj_rel_insert_input {
  data: users_insert_input;
  on_conflict?: users_on_conflict | null;
}

/**
 * on conflict condition type for table "users"
 */
export interface users_on_conflict {
  constraint: users_constraint;
  update_columns: users_update_column[];
  where?: users_bool_exp | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
