import React from 'react'
import * as Sentry from '@sentry/browser'
import { useMutation } from '@apollo/client'
import { UploadRequestMutation } from '../graphql/optitorque-kanban-graphql'
import { uploadRequestMutation } from '../graphql/mutations/uploadRequest.mutation'

type UploadState = {
  loading: boolean
  done: boolean
  error: boolean
  progress: number
}

type UploadResetAction = {
  type: 'upload/reset'
}

type UploadStartAction = {
  type: 'upload/start'
}

type UploadProgressAction = {
  type: 'upload/progress'
  payload: {
    progress: number
  }
}

type UploadDoneAction = {
  type: 'upload/done'
  payload: {
    error: boolean
  }
}

type UploadAction =
  | UploadResetAction
  | UploadStartAction
  | UploadProgressAction
  | UploadDoneAction

const uploadReducerInitialState = {
  loading: false,
  done: false,
  error: false,
  progress: 0,
}

function uploadReducer(state: UploadState, action: UploadAction) {
  switch (action.type) {
    case 'upload/reset':
      return uploadReducerInitialState
    case 'upload/start':
      return {
        loading: true,
        done: false,
        error: false,
        progress: 0,
      }
    case 'upload/progress':
      return {
        ...state,
        progress: action.payload.progress,
      }
    case 'upload/done':
      return {
        ...state,
        loading: false,
        done: true,
        error: action.payload.error,
        progress: action.payload.error ? state.progress : 100,
      }
    default:
      throw new Error(`Unexpected action: ${action}`)
  }
}

export const UploadStateContext = React.createContext<UploadState>(
  uploadReducerInitialState
)

export const UploadDispatchContext = React.createContext<
  React.Dispatch<UploadAction>
>(() => {})

type UploadStateProviderProps = {
  children: React.ReactNode
}

export function UploadProvider({ children }: UploadStateProviderProps) {
  const [state, dispatch] = React.useReducer(
    uploadReducer,
    uploadReducerInitialState
  )

  return (
    <UploadDispatchContext.Provider value={dispatch}>
      <UploadStateContext.Provider value={state}>
        {children}
      </UploadStateContext.Provider>
    </UploadDispatchContext.Provider>
  )
}

export function useUpload() {
  const [uploadRequest] = useMutation<UploadRequestMutation>(
    uploadRequestMutation
  )

  const dispatch = React.useContext(UploadDispatchContext)

  const upload = React.useCallback(
    (file: File, onComplete: (signed_id: string) => void) => {
      uploadRequest()
        .then(({ data, errors }) => {
          if (errors?.length) {
            Sentry.withScope((scope) => {
              scope.setExtra('graphqlerrors', errors)
              scope.setLevel(Sentry.Severity.Error)
              Sentry.captureMessage(`uploadRequest() failed`)
            })
            dispatch({ type: 'upload/done', payload: { error: true } })
            return
          }

          if (!data || !data.uploadRequest) {
            Sentry.withScope((scope) => {
              scope.setLevel(Sentry.Severity.Error)
              Sentry.captureMessage(`uploadRequest() failed, empty data`)
            })
            dispatch({ type: 'upload/done', payload: { error: true } })
            return
          }

          let { id, presigned_url } = data.uploadRequest

          let xhr = new XMLHttpRequest()
          xhr.addEventListener('abort', () => {
            dispatch({ type: 'upload/reset' })
          })

          xhr.addEventListener('error', (e) => {
            Sentry.captureException(e)
            dispatch({ type: 'upload/done', payload: { error: true } })
          })

          xhr.upload.addEventListener('progress', (e) => {
            if (e.lengthComputable) {
              let progress = Math.round((e.loaded / e.total) * 100)
              dispatch({ type: 'upload/progress', payload: { progress } })
            }
          })

          xhr.addEventListener('loadstart', () => {
            dispatch({ type: 'upload/start' })
          })

          xhr.addEventListener('load', () => {
            let hasError = xhr.status < 200 || xhr.status >= 300
            dispatch({ type: 'upload/done', payload: { error: hasError } })
            onComplete(id)
          })

          xhr.open('PUT', presigned_url)
          xhr.setRequestHeader('x-amz-acl', 'public-read')
          xhr.send(file)
        })
        .catch((err) => {
          Sentry.withScope((scope) => {
            scope.setExtra('error', err)
            scope.setLevel(Sentry.Severity.Error)
            Sentry.captureMessage(`uploadRequest() failed`)
          })
          dispatch({ type: 'upload/done', payload: { error: true } })
        })
    },
    [dispatch, uploadRequest]
  )

  return upload
}
