import React from 'react'
import cx from 'classnames'
import { card_state_enum } from '../graphql/optitorque-kanban-graphql'

type Props = {
  state: card_state_enum
  size: number
  className?: string
}

export function CardStateIndicator({ state, size, className }: Props) {
  let bgColor = cx({
    'bg-blue-600': card_state_enum.BLUE === state,
    'bg-green-400': card_state_enum.GREEN === state,
    'bg-yellow-300': card_state_enum.YELLOW === state,
    'bg-red-400': card_state_enum.RED === state,
  })
  return (
    <span
      className={`rounded-full ${bgColor} w-${size} h-${size} ${
        className || ''
      }`}
    />
  )
}
