export function parseUTCDate(date: string) {
  let x = new Date(date)
  return Date.UTC(
    x.getFullYear(),
    x.getMonth(),
    x.getDate(),
    x.getHours(),
    x.getMinutes(),
    x.getSeconds()
  )
}
