import { useAccessToken } from '../components/ApolloProvider'

export function useIsAllowed(allowedRoles: string[]): boolean {
  let accessToken = useAccessToken()
  let isAllowed = false

  if (accessToken && accessToken['https://hasura.io/jwt/claims']) {
    for (let role of allowedRoles) {
      if (
        accessToken['https://hasura.io/jwt/claims'][
          'x-hasura-allowed-roles'
        ].some((userRole) => userRole === role)
      ) {
        isAllowed = true
      }
    }
  }

  return isAllowed
}
