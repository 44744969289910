import gql from 'graphql-tag'
import { cardTimeFragment } from './cardTime.fragment'
import { cardAttachmentFragment } from './cardAttachment.fragment'
import { cardCommentFragment } from './cardComment.fragment'
import { cardActivityFragment } from './cardActivity.fragment'

export const cardModalFragment = gql`
  fragment CardModalFragment on cards {
    id
    title
    description
    author {
      id
      email
      name
    }
    created_at
    updated_at
    is_public
    is_priority
    state
    card_times(order_by: { start: asc }) {
      ...CardTimeFragment
    }
    card_attachments(order_by: { created_at: asc }) {
      ...CardAttachmentFragment
    }
    card_comments(order_by: { created_at: asc }) {
      ...CardCommentFragment
    }
    card_activity(order_by: { created_at: asc }) {
      ...CardActivityFragment
    }
  }
  ${cardTimeFragment}
  ${cardAttachmentFragment}
  ${cardCommentFragment}
  ${cardActivityFragment}
`
