import * as Sentry from '@sentry/browser'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  blacklistUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
})
