import React from 'react'
import Modal from 'react-modal'
import { Auth0Provider } from '@auth0/auth0-react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { ApolloProvider } from './components/ApolloProvider'
import { BoardPage } from './components/BoardPage'
import { LoginPage } from './components/LoginPage'
import { TeamPage } from './components/TeamPage'
import { ProfilePage } from './components/ProfilePage'
import { TimeclockPage } from './components/TimeclockPage'
import { TimeclockUserEditPage } from './components/TimeclockUserEditPage'
import { PrivateRoute } from './components/PrivateRoute'
import { authDomain, authClientId, authAudience } from './constants'
import './base.css'
import './icons'
import { NotAllowedPage } from './components/NotAllowedPage'

Modal.setAppElement('#root')

function App() {
  return (
    <React.StrictMode>
      <Auth0Provider
        domain={authDomain}
        clientId={authClientId}
        redirectUri={window.location.origin}
        audience={authAudience}
      >
        <Router>
          <ApolloProvider>
            <Switch>
              <Route path="/login" exact>
                <LoginPage />
              </Route>
              <Route path="/team" exact>
                <TeamPage />
              </Route>
              <Route path="/profile" exact>
                <ProfilePage />
              </Route>
              <PrivateRoute allowedRoles={['admin']} path="/timeclock" exact>
                <TimeclockPage />
              </PrivateRoute>
              <Route path="/timeclock/:userId/edit" exact>
                <TimeclockUserEditPage />
              </Route>
              <Route path="/not-allowed" exact>
                <NotAllowedPage />
              </Route>
              <Route path="/">
                <BoardPage />
              </Route>
            </Switch>
          </ApolloProvider>
        </Router>
      </Auth0Provider>
    </React.StrictMode>
  )
}

export default App
