import React from 'react'
import { CardActivityFragment } from '../graphql/optitorque-kanban-graphql'
import { Avatar } from './Avatar'

type Props = {
  cardID: number
  activity: CardActivityFragment[]
}

export function KanbanCardModalActivity({ cardID, activity }: Props) {
  return (
    <div className="text-sm leading-5 text-gray-900">
      {(activity || []).map((entry) => (
        <ActivityEntry key={entry.id} entry={entry} />
      ))}
    </div>
  )
}

type ActivityEntryProps = {
  entry: CardActivityFragment
}

function ActivityEntry({ entry }: ActivityEntryProps) {
  return (
    <div className="grid grid-cols-modalComment gap-x-2 mt-4">
      <div className="row-span-2">
        <Avatar email={entry.actor.email} tailwindSize={9} />
      </div>
      <div>
        <div className="leading-5 truncate">
          <span className="text-sm font-medium">{entry.actor.name}</span>{' '}
          <span className="whitespace-pre-wrap">{entry.action}.</span>
        </div>
        <div className="text-xs text-gray-400">
          {new Date(entry.created_at).toLocaleString()}
        </div>
      </div>
    </div>
  )
}
