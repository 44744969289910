import gql from 'graphql-tag'
import { cardFragment } from '../fragments/card.fragment'

export const insertCardMutation = gql`
  mutation InsertCardMutation($input: cards_insert_input!) {
    insert_cards_one(object: $input) {
      ...CardFragment
    }
  }
  ${cardFragment}
`
