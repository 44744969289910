import { library } from '@fortawesome/fontawesome-svg-core'
import { faExclamationTriangle, faPlus } from '@fortawesome/pro-light-svg-icons'
import {
  faArchive,
  faCheck,
  faCopy,
  faExclamationCircle,
  faPaperclip,
  faTimes,
  faTrafficLight,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faAngleLeft,
  faAngleRight,
  faPencil,
  faSignOutAlt,
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  faAngleLeft,
  faAngleRight,
  faArchive,
  faCheck,
  faCopy,
  faExclamationCircle,
  faExclamationTriangle,
  faPaperclip,
  faPencil,
  faPlus,
  faSignOutAlt,
  faTimes,
  faTrafficLight,
  faTrashAlt
)
