import { useCallback, useState } from 'react'
import {
  addDays,
  addWeeks,
  endOfWeek,
  format,
  startOfWeek,
  subWeeks,
} from 'date-fns/esm'

export type Week = {
  startOfWeek: string
  endOfWeek: string
}

export function weekFormat(date: Date): string {
  return format(date, 'MM/d/yyyy')
}

export function useWeek(initialWeek?: Date) {
  const [week, setWeek] = useState<Week>(() => {
    const defaultDate = initialWeek || new Date()
    return {
      startOfWeek: weekFormat(startOfWeek(defaultDate, { weekStartsOn: 1 })),
      endOfWeek: weekFormat(
        addDays(endOfWeek(defaultDate, { weekStartsOn: 1 }), 1)
      ),
    }
  })

  const gotoPrevWeek = useCallback(() => {
    setWeek((v) => {
      return {
        startOfWeek: weekFormat(subWeeks(new Date(v.startOfWeek), 1)),
        endOfWeek: weekFormat(subWeeks(new Date(v.endOfWeek), 1)),
      }
    })
  }, [])

  const gotoNextWeek = useCallback(() => {
    setWeek((v) => {
      return {
        startOfWeek: weekFormat(addWeeks(new Date(v.startOfWeek), 1)),
        endOfWeek: weekFormat(addWeeks(new Date(v.endOfWeek), 1)),
      }
    })
  }, [])

  return { week, gotoPrevWeek, gotoNextWeek }
}
