import gql from 'graphql-tag'

export const latestCardTimeQuery = gql`
  query LatestCardTimeQuery($cardId: Int!) {
    card_time(
      where: { card_id: { _eq: $cardId } }
      order_by: { start: desc }
      limit: 1
    ) {
      id
      start
      stop
    }
  }
`
