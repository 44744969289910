import React from 'react'
import * as yup from 'yup'
import * as Sentry from '@sentry/browser'
import {
  CardCommentFragment,
  MeQuery,
  MeQueryVariables,
  InsertCardCommentMutation,
  InsertCardCommentMutationVariables,
  CardModalFragment,
  InsertCardActivityMutation,
  InsertCardActivityMutationVariables,
} from '../graphql/optitorque-kanban-graphql'
import { Avatar } from './Avatar'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery, useMutation } from '@apollo/client'
import { meQuery } from '../graphql/queries/me.query'
import { useFormik, FormikConfig } from 'formik'
import { insertCardCommentMutation } from '../graphql/mutations/insertCardComment.mutation'
import { cardModalFragment } from '../graphql/fragments/cardModal.fragment'
import { useBoolean } from '../hooks/use-boolean'
import { insertCardActivityMutation } from '../graphql/mutations/insertCardActivity.mutation'

type Props = {
  cardID: number
  comments: CardCommentFragment[]
}

export function KanbanCardModalComments({ cardID, comments }: Props) {
  return (
    <div className="text-sm leading-5 text-gray-900">
      {(comments || []).map((comment) => (
        <Comment key={comment.id} comment={comment} />
      ))}
      <CommentForm cardID={cardID} />
    </div>
  )
}

type CommentProps = {
  comment: CardCommentFragment
}

function Comment({ comment }: CommentProps) {
  return (
    <div className="grid grid-cols-modalComment gap-x-2 mt-4">
      <div className="row-span-2">
        <Avatar email={comment.author.email} tailwindSize={9} />
      </div>
      <div>
        <div className="leading-5 truncate">
          <span className="text-sm font-medium">{comment.author.name}</span>
          <span className="ml-2 text-xs text-gray-400">
            {new Date(comment.created_at).toLocaleString()}
          </span>
        </div>
      </div>
      <div className="mt-1 whitespace-pre-wrap">{comment.message}</div>
    </div>
  )
}

const commentSchema = yup.object({
  message: yup.string().required('Required'),
})

type CommentFormProps = {
  cardID: number
}

const errorClasses = 'mt-2 -mb-2 text-red-500 text-sm'

function CommentForm({ cardID }: CommentFormProps) {
  const { user } = useAuth0()
  const { data, loading, error } = useQuery<MeQuery, MeQueryVariables>(
    meQuery,
    {
      variables: {
        email: user.email,
      },
    }
  )
  const [hasFocus, { on, off }] = useBoolean(false)

  const [insertComment] = useMutation<
    InsertCardCommentMutation,
    InsertCardCommentMutationVariables
  >(insertCardCommentMutation)

  const [insertCardActivity] = useMutation<
    InsertCardActivityMutation,
    InsertCardActivityMutationVariables
  >(insertCardActivityMutation)

  const handleSubmit: FormikConfig<{ message: string }>['onSubmit'] = async ({
    message,
  }) => {
    let author = data?.users[0]

    if (!author) {
      Sentry.withScope((scope) => {
        scope.setLevel(Sentry.Severity.Error)
        Sentry.captureMessage('insertCardComment(): author is undefined')
      })
      return
    }

    try {
      await insertComment({
        variables: {
          input: {
            card_id: cardID,
            message,
          },
        },
        optimisticResponse: {
          insert_card_comment_one: {
            id: Math.random() * 1000 * -1,
            author,
            message: message,
            created_at: new Date().toISOString(),
            __typename: 'card_comment',
          },
        },
        update: (cache, { data }) => {
          let cardComment = data?.insert_card_comment_one

          if (!cardComment) return

          try {
            let card = cache.readFragment<CardModalFragment>({
              fragment: cardModalFragment,
              fragmentName: 'CardModalFragment',
              id: `cards:${cardID}`,
            })

            if (!card) return

            cache.writeFragment<CardModalFragment>({
              fragment: cardModalFragment,
              fragmentName: 'CardModalFragment',
              id: `cards:${cardID}`,
              data: {
                ...card,
                card_comments: [...card.card_comments, cardComment],
              },
            })
          } catch (err) {
            Sentry.captureException(err)
          }
        },
      })
      insertCardActivity({
        variables: {
          input: {
            card_id: cardID,
            action: 'made a comment on this card',
          },
        },
      })
      formik.resetForm()
    } catch (err) {
      Sentry.captureException(err)
    }
  }

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: commentSchema,
    onSubmit: handleSubmit,
  })

  const handleCancel = () => formik.resetForm()

  // TODO error handling
  if (loading || error || !data?.users || !data.users.length) return null

  return (
    <div className="grid grid-cols-modalComment gap-x-2 mt-4">
      <div className="row-span-2">
        <Avatar email={user.email} tailwindSize={9} className="mt-1" />
      </div>
      <div className="leading-5 font-medium truncate">{data.users[0].name}</div>
      <div>
        <form className="flex flex-col mb-1" onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <div className="mt-1 rounded-md shadow-sm">
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  onFocus={on}
                  onBlur={off}
                  onChange={formik.handleChange}
                  value={formik.values.message}
                  className="block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                ></textarea>
              </div>
              {formik.touched.message && formik.errors.message ? (
                <p className={errorClasses}>{formik.errors.message}</p>
              ) : null}
            </div>
          </div>
          <div className="flex items-center justify-end mt-3 space-x-2">
            {(hasFocus || formik.values.message) && (
              <>
                <span className="rounded-md shadow-sm">
                  <button
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 active:bg-indigo-700"
                    type="submit"
                  >
                    Save
                  </button>
                </span>
                <span className="rounded-md shadow-sm">
                  <button
                    className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 active:text-gray-800 active:bg-gray-50"
                    type="button"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </span>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}
