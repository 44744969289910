import gql from 'graphql-tag'
import { cardStateFragment } from '../fragments/cardState.fragment'

export const updateCardStateMutation = gql`
  mutation UpdateCardStateMutation(
    $input: cards_set_input!
    $ids: cards_pk_columns_input!
  ) {
    update_cards_by_pk(_set: $input, pk_columns: $ids) {
      ...CardStateFragment
    }
  }
  ${cardStateFragment}
`
