import React, { useContext } from 'react'
import * as Sentry from '@sentry/browser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutation } from '@apollo/client'
import {
  InsertCardAttachmentMutation,
  InsertCardAttachmentMutationVariables,
  CardModalFragment,
  InsertCardActivityMutation,
  InsertCardActivityMutationVariables,
} from '../graphql/optitorque-kanban-graphql'
import { insertCardAttachmentMutation } from '../graphql/mutations/insertCardAttachment.mutation'
import { useUpload, UploadDispatchContext } from '../hooks/use-upload'
import { cardModalFragment } from '../graphql/fragments/cardModal.fragment'
import { insertCardActivityMutation } from '../graphql/mutations/insertCardActivity.mutation'

type Props = {
  cardID: number
}

export function AttachFilesAction({ cardID }: Props) {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const [insertCardAttachment] = useMutation<
    InsertCardAttachmentMutation,
    InsertCardAttachmentMutationVariables
  >(insertCardAttachmentMutation)

  const [insertCardActivity] = useMutation<
    InsertCardActivityMutation,
    InsertCardActivityMutationVariables
  >(insertCardActivityMutation)

  const dispatch = useContext(UploadDispatchContext)
  const upload = useUpload()

  const uploadFile = React.useCallback(() => {
    let input = inputRef.current
    if (input?.files?.length) {
      let file = input.files[0]
      upload(file, (signed_id) => {
        insertCardAttachment({
          variables: {
            input: {
              card_id: cardID,
              name: file.name,
              size: file.size,
              type: file.type,
              signed_id: signed_id,
            },
          },
          update: (cache, { data }) => {
            if (!data?.insert_card_attachment_one) return

            try {
              let cardModalData = cache.readFragment<CardModalFragment>({
                id: `cards:${cardID}`,
                fragment: cardModalFragment,
                fragmentName: 'CardModalFragment',
              })

              if (!cardModalData) {
                Sentry.withScope((scope) => {
                  scope.setExtra('cardID', cardID)
                  scope.setLevel(Sentry.Severity.Error)
                  Sentry.captureMessage(
                    `insert card attachment cache update failed, cache response empty`
                  )
                })
                return
              }

              let attachments = [
                ...cardModalData.card_attachments,
                data.insert_card_attachment_one,
              ]

              cache.writeFragment<CardModalFragment>({
                id: `cards:${cardID}`,
                fragment: cardModalFragment,
                fragmentName: 'CardModalFragment',
                data: {
                  ...cardModalData,
                  card_attachments: attachments,
                },
              })
            } catch (e) {
              Sentry.withScope((scope) => {
                scope.setExtra('error', e)
                scope.setExtra('cardID', cardID)
                scope.setLevel(Sentry.Severity.Error)
                Sentry.captureMessage(
                  `insert card attachment cache update failed`
                )
              })
            }
          },
        })
          .then(({ errors }) => {
            if (errors?.length) {
              // TODO inform user
              Sentry.withScope((scope) => {
                scope.setExtra('graphqlerrors', errors)
                scope.setLevel(Sentry.Severity.Error)
                Sentry.captureMessage(`insertCardAttachment() failed`)
              })
            } else {
              insertCardActivity({
                variables: {
                  input: {
                    card_id: cardID,
                    action: 'uploaded an attachment to this card',
                  },
                },
              })
            }
          })
          .catch((err) => {
            // TODO inform user
            Sentry.captureException(err)
          })
      })
    }
  }, [insertCardAttachment, insertCardActivity, cardID, upload])

  React.useEffect(() => {
    let input = inputRef.current
    if (!input) return

    const stopPropagation = (e: MouseEvent) => {
      e.stopPropagation()
    }

    input.addEventListener('click', stopPropagation)
    input.addEventListener('change', uploadFile)

    return () => {
      input?.removeEventListener('click', stopPropagation)
      input?.removeEventListener('change', uploadFile)
    }
  }, [uploadFile])

  const openDialog = () => {
    if (inputRef.current) {
      dispatch({ type: 'upload/reset' })
      inputRef.current.click()
    }
  }

  return (
    <button
      type="button"
      onClick={openDialog}
      className="w-full inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-indigo-700 hover:bg-indigo-100 focus:outline-none focus:border-indigo-300 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 active:bg-indigo-200"
    >
      <FontAwesomeIcon
        icon={['far', 'paperclip']}
        className="-ml-0.5 mr-2 h-4 w-4"
      />
      Attach Files
      <input ref={inputRef} type="file" className="hidden" />
    </button>
  )
}
