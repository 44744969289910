import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { useApolloClient } from '@apollo/client'
import {
  ColumnsQuery_columns_cards,
  InsertCardMutation,
  InsertCardMutationVariables,
  CardFragment,
  InsertCardActivityMutation,
  InsertCardActivityMutationVariables,
} from '../graphql/optitorque-kanban-graphql'
import { insertCardMutation } from '../graphql/mutations/insertCard.mutation'
import { KanbanCard } from './KanbanCard'
import { KanbanCardForm } from './KanbanCardForm'
import { KanbanColumnHeader } from './KanbanColumnHeader'
import { insertCardActivityMutation } from '../graphql/mutations/insertCardActivity.mutation'

type Props = {
  colId: number
  title: string
  cards: ColumnsQuery_columns_cards[]
  openKanbanCardModal: (cardID: number) => void
}

export function KanbanColumn({
  colId,
  title,
  cards,
  openKanbanCardModal,
}: Props) {
  const client = useApolloClient()
  const [isCardFormOpen, setIsCardFormOpen] = React.useState(false)

  const openCardForm = () => setIsCardFormOpen(true)
  const closeCardForm = () => setIsCardFormOpen(false)

  const handleSave = (values: { title: string; description: string }) => {
    const { title, description } = values
    client
      .mutate<InsertCardMutation, InsertCardMutationVariables>({
        mutation: insertCardMutation,
        variables: {
          input: {
            column_id: colId,
            description: description,
            is_public: true,
            title: title,
          },
        },
      })
      .then(({ data }) => {
        if (data?.insert_cards_one) {
          client.mutate<
            InsertCardActivityMutation,
            InsertCardActivityMutationVariables
          >({
            mutation: insertCardActivityMutation,
            variables: {
              input: {
                card_id: data.insert_cards_one.id,
                action: `created this card in ${title}`,
              },
            },
          })
        }
      })
    closeCardForm()
  }

  let sortedCards = [...cards].sort(orderAsc)

  return (
    <section
      className="bg-gray-100 border border-gray-400 rounded flex flex-col overflow-hidden ml-2 first:ml-0"
      style={{ width: '20rem', minWidth: '20rem' }}
    >
      <KanbanColumnHeader title={title} onOpenCardForm={openCardForm} />

      <div className="overflow-auto flex flex-grow flex-col px-4 pb-2">
        {isCardFormOpen && (
          <KanbanCardForm onSave={handleSave} onCancel={closeCardForm} />
        )}

        <Droppable droppableId={`${colId}`}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="flex-1"
            >
              {sortedCards.map((card, index) => {
                return (
                  <KanbanCard
                    key={card.id}
                    cardId={card.id}
                    title={card.title}
                    email={card.author.email}
                    description={card.description}
                    state={card.state}
                    is_priority={card.is_priority}
                    onClick={openKanbanCardModal}
                    index={index}
                  />
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </section>
  )
}

function orderAsc(a: CardFragment, b: CardFragment): number {
  if (a.order < b.order) return -1
  if (a.order > b.order) return 1
  return 0
}
