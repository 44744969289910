import gql from 'graphql-tag'
import { cardAttachmentFragment } from '../fragments/cardAttachment.fragment'

export const insertCardAttachmentMutation = gql`
  mutation InsertCardAttachmentMutation($input: card_attachment_insert_input!) {
    insert_card_attachment_one(object: $input) {
      ...CardAttachmentFragment
    }
  }
  ${cardAttachmentFragment}
`
