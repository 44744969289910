import React from 'react'
import { useQuery } from '@apollo/client'
import { MeQuery, MeQueryVariables } from '../graphql/optitorque-kanban-graphql'
import { meQuery } from '../graphql/queries/me.query'
import { AppPage } from './AppPage'
import { useAuth0 } from '@auth0/auth0-react'
import { Profile } from './Profile'

export function ProfilePage() {
  const { user } = useAuth0()
  const { data, loading, error } = useQuery<MeQuery, MeQueryVariables>(
    meQuery,
    {
      variables: {
        email: user.email,
      },
    }
  )

  return (
    <AppPage>
      {loading || error || !data || !data.users.length ? null : (
        <Profile user={data.users[0]} />
      )}
    </AppPage>
  )
}
