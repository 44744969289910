import gql from 'graphql-tag'
import { clockpunchFragment } from '../fragments/clockpunch.fragment'

export const insertClockpunchMutation = gql`
  mutation InsertClockpunchMutation(
    $userId: String!
    $punchIn: timestamptz!
    $punchOut: timestamptz
  ) {
    insert_clockpunches_one(
      object: { user_id: $userId, punch_in: $punchIn, punch_out: $punchOut }
    ) {
      ...ClockpunchFragment
    }
  }
  ${clockpunchFragment}
`
