import React from 'react'
import { useQuery } from '@apollo/client'
import { TeamQuery } from '../graphql/optitorque-kanban-graphql'
import { teamQuery } from '../graphql/queries/team.query'
import { AppPage } from './AppPage'
import { Avatar } from './Avatar'
import { useAccessToken } from './ApolloProvider'

export function TeamPage() {
  const { data, loading, error } = useQuery<TeamQuery>(teamQuery)
  const accessToken = useAccessToken()
  const userId =
    accessToken && accessToken['https://hasura.io/jwt/claims']
      ? accessToken['https://hasura.io/jwt/claims']['x-hasura-user-id']
      : null
  const role =
    accessToken && accessToken['https://hasura.io/jwt/claims']
      ? accessToken['https://hasura.io/jwt/claims']['x-hasura-default-role']
      : null

  return (
    <AppPage>
      {loading || error ? null : (
        <div className="h-full w-full bg-cool-gray-100">
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-10">
              {data?.users.map((user) => {
                return (
                  <li
                    key={user.id}
                    className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow"
                  >
                    <div className="flex-1 flex flex-col p-8">
                      <Avatar
                        tailwindSize={32}
                        email={user.email}
                        className="flex-shrink-0 mx-auto bg-black"
                      />
                      <h3 className="mt-6 text-gray-900 text-sm leading-5 font-medium">
                        {user.name}
                      </h3>
                      <dl className="mt-1 flex-grow flex flex-col justify-between">
                        {user.title && (
                          <>
                            <dt className="sr-only">Title</dt>
                            <dd className="text-gray-500 text-sm leading-5 mb-1">
                              {user.title}
                            </dd>
                          </>
                        )}
                        {user.phone && (
                          <>
                            <dt className="sr-only">Phone</dt>
                            <dd className="text-gray-500 text-sm leading-5">
                              {user.phone}
                            </dd>
                          </>
                        )}
                        {!!role && user.id === userId ? (
                          <>
                            <dt className="sr-only">Role</dt>
                            <dd className="mt-3">
                              <span className="px-2 py-1 text-teal-800 text-xs leading-4 font-medium bg-purple-600 text-white  rounded-full">
                                {role}
                              </span>
                            </dd>
                          </>
                        ) : null}
                      </dl>
                    </div>
                    <div className="border-t border-gray-200">
                      <div className="-mt-px flex">
                        <div className="w-0 flex-1 flex border-r border-gray-200">
                          <a
                            href={`mailto:${user.email}`}
                            className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 focus:border-blue-300 focus:z-10 transition ease-in-out duration-150"
                          >
                            <svg
                              className="w-5 h-5 text-gray-400"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                            </svg>
                            <span className="ml-3">Email</span>
                          </a>
                        </div>
                        <div className="-ml-px w-0 flex-1 flex">
                          <a
                            href={user.phone ? `tel:${user.phone}` : '#'}
                            className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 focus:border-blue-300 focus:z-10 transition ease-in-out duration-150"
                          >
                            <svg
                              className="w-5 h-5 text-gray-400"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                            </svg>
                            <span className="ml-3">Call</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      )}
    </AppPage>
  )
}
