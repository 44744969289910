import * as yup from 'yup'
import cx from 'classnames'
import { useMutation } from '@apollo/client'
import { useFormik } from 'formik'
import { insertClockpunchMutation } from '../graphql/mutations/insertClockpunch.mutation'
import {
  InsertClockpunchMutation,
  InsertClockpunchMutationVariables,
  MeFragment,
} from '../graphql/optitorque-kanban-graphql'
import { Week } from '../hooks/use-week'
import { timeToTimestamp, timeRegex } from './TimeclockUserEditRow'
import { clockpunchFragment } from '../graphql/fragments/clockpunch.fragment'

type Props = {
  week: Week
  user: MeFragment
  onDone: () => void
}

export function TimeclockUserInsertRow({ week, user, onDone }: Props) {
  const [insertClockpunch] = useMutation<
    InsertClockpunchMutation,
    InsertClockpunchMutationVariables
  >(insertClockpunchMutation)

  const formikProps = useFormik<{
    date: string
    punchIn: string
    punchOut: string
  }>({
    initialValues: {
      date: week.startOfWeek,
      punchIn: '',
      punchOut: '',
    },
    validationSchema: yup.object().shape({
      date: yup
        .date()
        .transform((value, originalValue) => {
          const timestamp = Date.parse(value)
          if (isNaN(timestamp)) {
            return new Date('')
          }
          return new Date(timestamp)
        })
        .required(),
      punchIn: yup.string().required().matches(timeRegex),
      punchOut: yup.string().required().matches(timeRegex),
    }),
    onSubmit: (values) => {
      const pIn = new Date(timeToTimestamp(values.punchIn))
      const pOut = new Date(timeToTimestamp(values.punchOut))
      const newDate = new Date(values.date)

      pIn.setFullYear(newDate.getFullYear())
      pIn.setMonth(newDate.getMonth())
      pIn.setDate(newDate.getDate())

      pOut.setFullYear(newDate.getFullYear())
      pOut.setMonth(newDate.getMonth())
      pOut.setDate(newDate.getDate())

      insertClockpunch({
        variables: {
          userId: user.id,
          punchIn: pIn.toISOString(),
          punchOut: pOut.toISOString(),
        },
        update: (cache, { data }) => {
          if (!data?.insert_clockpunches_one) return

          cache.modify({
            id: `${user.__typename}:${user.id}`,
            fields: {
              clockpunches(existingClockpunchRefs = [], { readField }) {
                const newClockpunchRef = cache.writeFragment({
                  data: data.insert_clockpunches_one,
                  fragment: clockpunchFragment,
                })

                // Quick safety check - if the new comment is already
                // present in the cache, we don't need to add it again.
                if (
                  existingClockpunchRefs.some(
                    (ref: any) =>
                      readField('id', ref) === data.insert_clockpunches_one!.id
                  )
                ) {
                  return existingClockpunchRefs
                }

                return [...existingClockpunchRefs, newClockpunchRef]
              },
            },
          })
        },
      }).then(() => onDone())
    },
  })

  return (
    <tr>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-10 text-gray-500">
        <input
          type="text"
          autoFocus
          {...formikProps.getFieldProps('date')}
          className={cx(
            'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md focus:outline-none',
            {
              'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500':
                formikProps.touched.date && formikProps.errors.date,
            }
          )}
        />
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-10 text-gray-500">
        <input
          type="text"
          {...formikProps.getFieldProps('punchIn')}
          className={cx(
            'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md focus:outline-none',
            {
              'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500':
                formikProps.touched.punchIn && formikProps.errors.punchIn,
            }
          )}
        />
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-10 text-gray-500">
        <input
          type="text"
          {...formikProps.getFieldProps('punchOut')}
          className={cx(
            'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md focus:outline-none',
            {
              'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500':
                formikProps.touched.punchOut && formikProps.errors.punchOut,
            }
          )}
        />
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-10 text-gray-500">
        {/* TODO: code */}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-sm leading-10 text-gray-500">
        <button
          type="button"
          onClick={() => formikProps.handleSubmit()}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save
        </button>
        <button
          type="button"
          onClick={onDone}
          className="ml-4 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
      </td>
    </tr>
  )
}
