import gql from 'graphql-tag'
import { meFragment } from '../fragments/me.fragment'

export const meQuery = gql`
  query MeQuery($email: String!) {
    users(where: { email: { _eq: $email } }) {
      ...MeFragment
    }
  }
  ${meFragment}
`
