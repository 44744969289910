import gql from 'graphql-tag'

export const cardTimeFragment = gql`
  fragment CardTimeFragment on card_time {
    id
    diff
    start
    stop
    user {
      name
      email
    }
  }
`
