import gql from 'graphql-tag'
import { cardFragment } from '../fragments/card.fragment'
import { columnFragment } from '../fragments/column.fragment'
import { userFragment } from '../fragments/user.fragment'

export const columnsQuery = gql`
  query ColumnsQuery {
    columns {
      ...ColumnFragment
      owner {
        ...UserFragment
      }
      cards(order_by: { order: asc }) {
        ...CardFragment
      }
    }
  }
  ${columnFragment}
  ${userFragment}
  ${cardFragment}
`
