import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useBoolean } from '../hooks/use-boolean'

export function CopyTaskLinkAction() {
  const [copied, { on, off }] = useBoolean(false)

  const handleCopyTaskClick = () => {
    window.navigator.clipboard.writeText(window.location.href)
    on()
  }

  React.useEffect(() => {
    let timeout: ReturnType<typeof global.setTimeout>
    if (copied) {
      timeout = window.setTimeout(() => {
        off()
      }, 2000)
    }

    return () => {
      timeout && clearInterval(timeout)
    }
  }, [copied, off])

  return (
    <button
      type="button"
      onClick={handleCopyTaskClick}
      className="w-full inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-indigo-700 hover:bg-indigo-100 focus:outline-none focus:border-indigo-300 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 active:bg-indigo-200"
    >
      <FontAwesomeIcon
        icon={['far', 'copy']}
        className="-ml-0.5 mr-2 h-4 w-4"
      />
      {copied ? 'Copied!' : 'Copy task link'}
    </button>
  )
}
