import gql from 'graphql-tag'
import { cardCommentFragment } from '../fragments/cardComment.fragment'

export const insertCardCommentMutation = gql`
  mutation InsertCardCommentMutation($input: card_comment_insert_input!) {
    insert_card_comment_one(object: $input) {
      ...CardCommentFragment
    }
  }
  ${cardCommentFragment}
`
