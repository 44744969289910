import gql from 'graphql-tag'
import { cardActivityFragment } from '../fragments/cardActivity.fragment'

export const insertCardActivityMutation = gql`
  mutation InsertCardActivityMutation($input: card_activity_insert_input!) {
    insert_card_activity_one(object: $input) {
      ...CardActivityFragment
    }
  }
  ${cardActivityFragment}
`
