import React from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'

export type NavLinkProps = {
  to: string
  label: string
  active: boolean
  className?: string
}

export function NavLink({ to, label, active, className }: NavLinkProps) {
  const classes = cx(
    `px-3 py-2 rounded-md font-medium transition duration-150 ease-in-out focus:bg-gray-700 focus:text-white focus:outline-none ${className}`,
    {
      'text-white bg-gray-900 ': active,
      'text-gray-300 hover:text-white hover:bg-gray-700': !active,
    }
  )

  return (
    <Link to={to} className={classes}>
      {label}
    </Link>
  )
}
