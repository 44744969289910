import gql from 'graphql-tag'
import { cardPriorityFragment } from '../fragments/cardPriority.fragment'

export const updateCardPriorityMutation = gql`
  mutation UpdateCardPriorityMutation(
    $input: cards_set_input!
    $ids: cards_pk_columns_input!
  ) {
    update_cards_by_pk(_set: $input, pk_columns: $ids) {
      ...CardPriorityFragment
    }
  }
  ${cardPriorityFragment}
`
