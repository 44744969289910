import React from 'react'
import prettyMilliseconds from 'pretty-ms'
import { CardModalQuery_cards_by_pk_card_times } from '../graphql/optitorque-kanban-graphql'
import { Avatar } from './Avatar'

type Props = {
  cardTimes: CardModalQuery_cards_by_pk_card_times[]
}

export function KanbanCardModalTimeTracking(props: Props) {
  if (!props.cardTimes.length) {
    return null
  }

  const totalTime = props.cardTimes.reduce(
    (sum, { diff }) => sum + (diff || 0),
    0
  )

  if (totalTime <= 0) {
    return null
  }

  const nonZeroCardTimes = props.cardTimes.filter((time) => time.diff)

  return (
    <div className="flex flex-col">
      <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Date
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Time
                </th>
              </tr>
            </thead>
            <tbody>
              {nonZeroCardTimes.map((time, i) => {
                if (!time.diff || time.diff <= 0) {
                  return null
                }
                return (
                  <tr key={time.id} className="bg-white">
                    <td className="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                      <div className="flex items-center">
                        <Avatar email={time.user.email} tailwindSize={6} />
                        <span className="ml-2">{time.user.name}</span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                      {new Date(time.start).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                      {prettyMilliseconds(time.diff * 1000, {
                        verbose: true,
                      })}
                    </td>
                    {/* TODO: ADD EDIT FOR ADMINS */}
                    {/* <td className="px-6 py-4 whitespace-nowrap text-right text-sm leading-5 font-medium">
                  <a href="#" className="text-indigo-600 hover:text-indigo-900">Edit
                  </a>
                </td> */}
                  </tr>
                )
              })}
              <tr className="bg-white border-t border-gray-200">
                <td
                  className="px-6 py-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900"
                  colSpan={3}
                >
                  <span className="font-semibold">Total: </span>
                  {prettyMilliseconds(totalTime * 1000, { verbose: true })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
