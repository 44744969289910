import { Header } from './Header'

export function NotAllowedPage() {
  return (
    <>
      <Header />
      <h1>You do not have permission to access this page</h1>
    </>
  )
}
