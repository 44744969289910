import React from 'react'
import { Header } from './Header'
import { useAuth0 } from '@auth0/auth0-react'
import { Redirect } from 'react-router-dom'

type Props = {
  children: React.ReactNode
}

export function AppPage(props: Props) {
  const { isLoading, isAuthenticated } = useAuth0()

  if (isLoading) {
    return <Header />
  }

  if (!isAuthenticated) {
    return <Redirect to={`/login${window.location.search}`} />
  }

  return (
    <>
      <Header />
      {props.children}
    </>
  )
}
