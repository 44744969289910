import gql from 'graphql-tag'
import {userFragment} from './user.fragment'

export const cardCommentFragment = gql`
  fragment CardCommentFragment on card_comment {
    id
    created_at
    message
    author {
      ...UserFragment
    }
  }
  ${userFragment}
`
