import React from 'react'
import cx from 'classnames'
import * as Sentry from '@sentry/browser'
import OutsideClickHandler from 'react-outside-click-handler'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutation } from '@apollo/client'
import { Transition } from '@headlessui/react'
import { useBoolean } from '../hooks/use-boolean'
import { updateCardPriorityMutation } from '../graphql/mutations/updateCardPriority.mutation'
import { insertCardActivityMutation } from '../graphql/mutations/insertCardActivity.mutation'
import {
  InsertCardActivityMutation,
  InsertCardActivityMutationVariables,
  UpdateCardPriorityMutation,
  UpdateCardPriorityMutationVariables,
} from '../graphql/optitorque-kanban-graphql'

type Props = {
  cardID: number
  is_priority: boolean
}

export function ChangeCardPriorityDropdown({ cardID, is_priority }: Props) {
  const [isOpen, { toggle: toggleOpen }] = useBoolean(false)

  const [updateCard] = useMutation<
    UpdateCardPriorityMutation,
    UpdateCardPriorityMutationVariables
  >(updateCardPriorityMutation)

  const [insertCardActivity] = useMutation<
    InsertCardActivityMutation,
    InsertCardActivityMutationVariables
  >(insertCardActivityMutation)

  const changeCardPriority = async (is_priority: boolean) => {
    toggleOpen()

    updateCard({
      variables: {
        ids: {
          id: cardID,
        },
        input: {
          is_priority,
        },
      },
      optimisticResponse: {
        update_cards_by_pk: {
          id: cardID,
          is_priority,
          __typename: 'cards',
        },
      },
    })
      .then(({ errors }) => {
        if (errors?.length) {
          Sentry.withScope((scope) => {
            scope.setExtra('GraphQLErrors', errors)
            scope.setLevel(Sentry.Severity.Error)
            Sentry.captureMessage(
              `updateCard(id=${cardID}, is_priority=${is_priority}) failed`
            )
          })
        }
      })
      .catch(Sentry.captureException)

    insertCardActivity({
      variables: {
        input: {
          card_id: cardID,
          action: `${is_priority ? 'prioritized' : 'deprioritized'} this card`,
        },
      },
    })
  }

  const handleOutsideClick = React.useCallback(() => {
    if (isOpen) {
      toggleOpen()
    }
  }, [isOpen, toggleOpen])

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <div className="relative">
        <div>
          <button
            type="button"
            onClick={toggleOpen}
            className="w-full inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-indigo-700 hover:bg-indigo-100 focus:outline-none focus:border-indigo-300 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 active:bg-indigo-200 transition ease-in-out duration-150"
          >
            <FontAwesomeIcon
              icon={['far', 'exclamation-circle']}
              className="-ml-0.5 mr-2 h-4 w-4"
            />
            Change Priority
          </button>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
            <div className="flex flex-col py-1 rounded-md bg-white ring-1 ring-black ring-opacity-5">
              <button
                type="button"
                disabled={is_priority === true}
                onClick={() => changeCardPriority(true)}
                className={cx(
                  'flex items-center flex-1 px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out',
                  {
                    'opacity-60': is_priority === true,
                    'pointer-events-none': is_priority === true,
                  }
                )}
              >
                <span className="mr-4">🔥</span>Prioritize
              </button>
            </div>
            <div className="flex flex-col py-1 rounded-md bg-white ring-1 ring-black ring-opacity-5">
              <button
                type="button"
                disabled={is_priority === false}
                onClick={() => changeCardPriority(false)}
                className={cx(
                  'flex items-center flex-1 px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out',
                  {
                    'opacity-60': is_priority === false,
                    'pointer-events-none': is_priority === false,
                  }
                )}
              >
                <span className="mr-4">❄</span>Deprioritize
              </button>
            </div>
          </div>
        </Transition>
      </div>
    </OutsideClickHandler>
  )
}
