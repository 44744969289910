import gql from 'graphql-tag'

export const softDeleteCardMutation = gql`
  mutation SoftDeleteCardMutation($id: Int!) {
    update_cards_by_pk(pk_columns: { id: $id }, _set: { deleted_at: "now()" }) {
      id
      deleted_at
    }
  }
`
