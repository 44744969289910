import React from 'react'
import gravatarUrl from 'gravatar-url'

type Props = {
  email: string
  tailwindSize?: number
  className?: string
}

export function Avatar({ email, tailwindSize, className }: Props) {
  let size = tailwindSize || 5
  return (
    <img
      alt={`${email} Avatar`}
      src={gravatarUrl(email, { size: size * 4, default: 'identicon' })}
      className={`rounded-full h-${size} w-${size} ${className || ''}`}
    />
  )
}
