import gql from 'graphql-tag'
import { cardTimeUpdateFragment } from '../fragments/cardTimeUpdate.fragment'

export const updateCardTimeMutation = gql`
  mutation UpdateCardTimeMutation($id: Int!, $stop: timestamp!) {
    update_card_time_by_pk(pk_columns: { id: $id }, _set: { stop: $stop }) {
      ...CardTimeUpdateFragment
    }
  }
  ${cardTimeUpdateFragment}
`
