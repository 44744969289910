export const BOARD_CACHE_KEY = 'kanban-board-cache'

export const HASURA_GRAPHQL_ENGINE_HOSTNAME =
  process.env.REACT_APP_HASURA_GRAPHQL_ENGINE_HOSTNAME ?? ''

const scheme = (proto: string) => {
  return window.location.protocol === 'https:' ? `${proto}s` : proto
}

export const GRAPHQL_URL = `${scheme(
  'http'
)}://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`

export const REALTIME_GRAPHQL_URL = `${scheme(
  'ws'
)}://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`

export const authClientId = process.env.REACT_APP_AUTH0_APP_CLIENT_ID ?? ''
export const authDomain = process.env.REACT_APP_AUTH0_APP_DOMAIN ?? ''
export const authNamespace = 'https://hasura.io/jwt/claims'
export const authAudience = process.env.REACT_APP_AUTH0_AUDIENCE ?? 'dev-hasura'

export const SPACES_CDN_URL = `${scheme('http')}://${
  process.env.REACT_APP_SPACES_CDN_URL
}`
